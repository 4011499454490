import React, { useRef, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";

const Sidebar = ({ onShowHideClick, hideSidebar, onToastyData }) => {
  const navigation = useNavigate();
  const sideNavIcon = useRef();
  const [openDrawer, setOpenDrawer] = useState(false);
  const adminSource = localStorage.getItem("admin_source");
  const userType = localStorage.getItem("userType");
 
  const clearSession = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    localStorage.removeItem("outlet_name");
    onToastyData({
      isToast: true,
      success: "success",
      message: "Logged Out Successfully",
    });
  };

  return (
    <div>
      {/* <aside className="dash-sidebar"> */}
      <aside className="fixed bottom-0 left-0 z-50 w-full h-16 bg-gray-100 border-t border-gray-200 text-sm md:text-base">
        <ul className="no-list sb_top_lv grid h-full max-w-lg grid-cols-5 mx-auto font-medium">
          <li className="inline-flex flex-col items-center justify-center px-5  group">
            <NavLink
              to="/orders"
              onClick={hideSidebar}
              className={({ isActive }) =>
                isActive ? "text-black" : "text-gray-500"
              }
            >
              <div className="flex flex-col items-center">
                <i className="bi bi-cart text-lg"></i>
                <span>Orders</span>
              </div>
            </NavLink>
          </li>
          <li className="inline-flex flex-col items-center justify-center px-5 group">
            <NavLink
              to="/ledger"
              onClick={hideSidebar}
              className={({ isActive }) =>
                isActive ? "text-black" : "text-gray-500 "
              }
            >
              <div className="flex flex-col items-center">
                <i className="bi bi-journal-text text-lg"></i>
                <span>Ledger</span>
              </div>
            </NavLink>
          </li>
          <li className="inline-flex flex-col items-center justify-center px-5  group">
            <NavLink
              to={(adminSource === "super" || userType === 'seller') && "/addproduct"}
              onClick={()=>{
                if (adminSource !== "super" && userType === 'admin') onToastyData({isToast: true, success: "warning", message: "You are not allowed to perform this action"})
                hideSidebar();
              }}
              className={({ isActive }) =>
                isActive ? "text-black" : "text-gray-500"
              }
            >
              <div className="flex flex-col items-center">
                <i className="bi bi-plus-square-fill text-lg"></i>
                <span>Add</span>
              </div>
            </NavLink>
          </li>
          <li className="inline-flex flex-col items-center justify-center px-5   group">
            <NavLink
              to="/inventory"
              onClick={hideSidebar}
              className={({ isActive }) =>
                isActive ? "text-black" : "text-gray-500"
              }
            >
              <div className="flex flex-col items-center">
                <i className="bi bi-box text-lg"></i>
                <span>Inventory</span>
              </div>
            </NavLink>
          </li>
          {userType === "seller" && (
            <li className="inline-flex flex-col items-center justify-center px-5  group">
              <NavLink
                to="/settings"
                onClick={hideSidebar}
                className={({ isActive }) =>
                  isActive ? "text-black" : "text-gray-500"
                }
              >
                <div className="flex flex-col items-center">
                  <i className="bi bi-gear text-lg"></i>
                  <span>Settings</span>
                </div>
              </NavLink>
            </li>
          )}
          {userType !== "seller" && (
            <li className="inline-flex flex-col items-center justify-center px-5  group">
              <button
                className={"text-gray-500"}
                type="button"
                onClick={() => setOpenDrawer(!openDrawer)}
              >
                <div className="flex flex-col items-center">
                  <i className="bi bi-list text-2xl"></i>
                  <span>More</span>
                </div>
              </button>
              <div
                className={`right-drawer-sidebar1`}
                style={{
                  transform: openDrawer ? "translateX(0)" : "translateX(100%)",
                }}
              >
                <div className="flex items-center h-[37px] pl-1" style={{backgroundColor: "#3e678e"}}>
                  <i
                    className="bi bi-x-lg hover:text-lg cursor-pointer text-md text-center w-[30px] bg-blue-300 rounded"
                    style={{backgroundColor: "#395671"}}
                    onClick={() => setOpenDrawer(!openDrawer)}
                  ></i>
                  <h2 className="ml-2 font-bold text-sm">Admin Menu</h2>
                </div>
                <div className="flex items-center right-sidebar-option cursor-pointer p-2.5">
                <i className="bi bi-clock-history text-center w-[30px]"></i>
                <div
                  onClick={() => {
                    navigation("/pendingRetailer");
                    setOpenDrawer(!openDrawer);
                  }}
                  className=""
                >
                  Pending Retailer Approvals
                </div>
                </div>
                {/* <hr className="bg-blue-200"/> */}
                <div className="flex items-center  right-sidebar-option cursor-pointer p-2.5"> 
                <i className="bi bi-clock-history text-center w-[30px]"></i>
                <div
                 onClick={() => {
                  navigation("/pendingProducts");
                  setOpenDrawer(!openDrawer);
                }}
                  // className="h-[40px] border-1 border-gray-700 border-solid bg-gray-300 hover:bg-blue-400 rounded-md cursor-pointer p-1.5"
                >
                  Pending Product Approvals
                </div>
                </div>
              </div>
            </li>
          )}
        </ul>
        <ul className="no-list sb_top_lv">
          <li>
            <NavLink to="/" onClick={clearSession} className="block">
              <div>
                <i className="bi bi-power"></i>
                <span>Logout</span>
              </div>
            </NavLink>
          </li>
          <li>
            <Link
              onClick={() => {
                onShowHideClick(sideNavIcon);
              }}
              style={{ cursor: "pointer" }}
            >
              <div>
                <i ref={sideNavIcon} className="bi bi-chevron-double-right"></i>
                <span>Hide</span>
              </div>
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  );
  // return (

  //   <div className="flex h-screen bg-white">
  //     <aside className="w-64 bg-blue-400 text-white p-4 flex flex-col fixed h-full">
  //       <div className="mb-4">
  //         <h2 className="text-2xl font-bold">{localStorage.getItem('outlet_name')}</h2>
  //       </div>
  //       <nav className="flex-grow overflow-y-auto">
  //         <ul>
  //           <li className="mb-2">
  //             <NavLink to="/orders" className="block px-4 py-2 rounded hover:bg-blue-700">Orders</NavLink>
  //           </li>
  //           <li className="mb-2">
  //             <NavLink to="/ledger" className="block px-4 py-2 rounded hover:bg-blue-700">Ledger</NavLink>
  //           </li>
  //           <li className="mb-2">
  //             <NavLink to="/inventory" className="block px-4 py-2 rounded hover:bg-blue-700">Inventory</NavLink>
  //           </li>
  //           {userType === 'seller' ?
  //             <NavLink to="/settings" className="block px-4 py-2 rounded hover:bg-blue-700">Settings</NavLink>
  //             : ''
  //           }
  //           <li className="mb-2">
  //             <NavLink to="/" onClick={clearSession} className="block px-4 py-2 hover:bg-blue-700">Logout</NavLink>
  //           </li>
  //           {/* Add more navigation items as needed */}
  //         </ul>
  //       </nav>
  //     </aside>
  //     <div className="ml-52 p-4">
  //       {/* Your right-side content goes here */}
  //     </div>
  //   </div>
  // );
};

export default Sidebar;
