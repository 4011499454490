import React, { useEffect, useState } from "react";
import {
  UpdateBulkOrderStatus,
  ordersCount,
  ordersData,
} from "../../services/Orders";
import Card from "../../components/Card";
import OrderCard from "../../components/OrderCard";
import Pagination from "../../components/pagination";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import download_csv from "../../assets/download_csv.png";
import { GridLoader } from "react-spinners";
import { InventoryFilters } from "../../services/Inventory";

const Orders = ({ onShowHideClick, onToastyData }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [orderData, setOrderData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [outlets, setOutlets] = useState([])
  const [outletChosen, setOutletChosen] = useState("")
  const [selectedDate, setSelectedDate] = useState(
    new Date()
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [orderArray, setOrderArray] = useState([]);
  const [buttonLabel, setButtonLabel] = useState("");
  const [statusChange, setStatusChange] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false)
  const [orderCount, setOrderCount] = useState({
    totalOrders: { count: 0, amount: 0 },
    deliveredOrders: { count: 0, amount: 0 },
    cancelledOrders: { count: 0, amount: 0 },
    pendingDeliveries: { count: 0, amount: 0 },
  });
  const loginType = localStorage.getItem("userType")
  const [statusDropdown, setStatusDropdown] = useState("");
  // const navigation = useNavigate();
  const firstRender = localStorage?.getItem("firstRender")
  const currentSelectedDate = localStorage.getItem("orderPageDate");
  const downloadcsv = async () => {
    const result = await ordersData(
      formatDate(selectedDate),
      currentPage,
      statusDropdown,
      outletChosen,
      "download"
    );
    const { data } = result;
    if (window?.flutter_inappwebview) {
      if (data?.length > 0) {
        const args = [result?.file, `orders_${formatDate(selectedDate)}.xlsx`];
        await window.flutter_inappwebview.callHandler('downloadFile', ...args).then(result => {
          if (result) {
            alert(`File Downloaded, Please Check in Downloads Folder`);
          } else {
            alert(`Somethng went wrong, please try again later`);
          }
        })
          .catch(error => {
            alert("Somethng went wrong, please try again later");
          });
      } else {
        onToastyData({ success: "warning", message: "No Orders Found for the Selected Date", isToast: true });
      }
    } else {
      if (data?.length > 0) {
        window.open(result?.file)
      } else {
        onToastyData({ success: "warning", message: "No Orders Found for the Selected Date", isToast: true });
      }
    }
  };


  useEffect(() => {
    const fetchCountData = async () => {
      try {
        const result = await ordersCount({
          date: formatDate(selectedDate),
          orderStatus: statusDropdown,
          provider_id: outletChosen
        });
        if (result === "logout") navigation("/login");
        setOrderCount({
          totalOrders: result.data.total,
          deliveredOrders: result.data.delivered,
          cancelledOrders: result.data.cancelled,
          pendingDeliveries: result.data.pendingDeliveries,
        });
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    const fetchOrderData = async () => {
      setSpinner(true)
      try {
        const result = await ordersData(
          formatDate(selectedDate),
          currentPage,
          statusDropdown,
          outletChosen
        );
        // if(result === false) { throw false; }
        setTotalPages(result.data.totalPages);
        setOrderData(result.data.result);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
      setSpinner(false)
    };
    fetchCountData();
    fetchOrderData();
    switch (statusDropdown) {
      case "Created":
        setButtonLabel("Accepted");
        break;
      case "Accepted":
        setButtonLabel("Packed");
        break;
      case "Packed":
        setButtonLabel("Picked");
        break;
      case "Picked":
        setButtonLabel("Dispatched");
        break;
      case "Dispatched":
        setButtonLabel("Delivered");
        break;
      default:
        setButtonLabel("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, currentPage, statusDropdown, statusChange, outletChosen]);

  const handleOrderStatusFunction = (data) => {
    setOrderArray(data);
  };

  useEffect(() => {
    if (!firstRender) {
      localStorage.setItem("firstRender", location.key)
      setShowBackButton(true)
    } else {
      if (location.key == firstRender) {
        setShowBackButton(true)
      }
    }

    if (currentSelectedDate === "" || !currentSelectedDate) {
      localStorage.setItem("orderPageDate", new Date().toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").reverse().join("-"))
      setSelectedDate(new Date().toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" }).split("/").reverse().join("-"))
    } else {
      setSelectedDate(currentSelectedDate)
    }

    (async function () {
      if (loginType === 'admin') {
        const filters = await InventoryFilters();
        setOutlets(filters?.outlets || []);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMarkOrderStatus = async () => {
    const result = await UpdateBulkOrderStatus({
      order_list: orderArray,
      status: buttonLabel,
    });
    if (result?.success) {
      setOrderArray([]);
      setStatusChange(true);
    }
  };

  const formatDate = (date) => {
    return new Date(date).toISOString().split("T")[0];
  };

  return (
    <>
      <Header paragraph={true} onToastyData={onToastyData} showBackButton={showBackButton} />
      <div className="mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
          {/* Blueish theme */}
          <Card
            title="Total Orders"
            quantity={orderCount.totalOrders.count ?? 0}
            amount={orderCount.totalOrders.amount ?? 0}
            type="total"
            widthClass="min-w-60"
          />

          {/* Blueish theme */}
          <Card
            title="Delivered Orders"
            quantity={orderCount.deliveredOrders.count ?? 0}
            amount={orderCount.deliveredOrders.amount ?? 0}
            type="del"
            widthClass="min-w-60"
          />

          {/* Red theme */}
          <Card
            title="Cancelled Orders"
            quantity={orderCount.cancelledOrders.count ?? 0}
            amount={orderCount.cancelledOrders.amount ?? 0}
            type="can"
            widthClass="min-w-60"
          />

          {/* Blueish theme */}
          <Card
            title="Pending Deliveries"
            quantity={orderCount.pendingDeliveries.count ?? 0}
            amount={orderCount.pendingDeliveries.amount ?? 0}
            type="pen"
            widthClass="min-w-60"
          />
        </div>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* <label className="text-md font-bold mb-2 mr-3">Select Date:</label> */}
          <div className="flex">
            <img onClick={downloadcsv} className="mx-1 border-2 border-gray-300 rounded-lg cursor-pointer" style={{ height: "50px", width: "50px" }} src={download_csv} alt="Logo" title="Download Order Data In CSV Format" />
            <div className="did-floating-label-content" style={{ marginBottom: "0", width: "90%" }}>
              <input
                class="did-floating-input"
                type="date"
                placeholder=""
                value={selectedDate}
                onChange={(e) => {
                  localStorage.setItem("orderPageDate", e.currentTarget.value)
                  setSelectedDate(e.currentTarget.value)
                }}
                maxDate={new Date()}
              />
              <label class="did-floating-label">Select Date</label>
            </div>
          </div>
          {loginType === "admin" && (
            <div className="did-floating-label-content" style={{ marginBottom: "0" }} >
              <select
                name="productCat"
                onChange={(e) => {
                  setOutletChosen(e.target.value);
                }}
                className="did-floating-input"
              // style={{ maxWidth: "400px" }}
              >
                <option key={-1} value="" className="did-floating-label">
                  Select Outlet
                </option>
                {outlets && outlets?.map(
                  (category, ind) => {
                    return (
                      <option
                        className="did-floating-label"
                        key={ind}
                        value={category?.unique_key_id}
                      >
                        {category?.outlet_name}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          )}
          <div className="did-floating-label-content" style={{ marginBottom: "0" }} >
            <select
              name="productCat"
              onChange={(e) => {
                setStatusDropdown(e.target.value);
                setOrderArray([]);
              }}
              className="did-floating-input">
              <option key={-1} value="" className="did-floating-label">
                Select Status
              </option>
              {["Created", "Accepted", "Packed", "Picked", "Dispatched", "Delivered"]?.map(
                (category, ind) => {
                  return (
                    <option
                      className="did-floating-label"
                      key={ind}
                      value={category}
                    >
                      {category}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          {statusDropdown !== "" && statusDropdown !== "Delivered" && orderArray.length > 0 && (
            <button
              onClick={handleMarkOrderStatus}
              className="flex items-center justify-center bg-green-500 hover:bg-red-500 text-white font-semibold px-3 rounded-lg"
              style={{ maxWidth: "190px", height: "50px" }}>
              {`Mark As ${buttonLabel}`}
            </button>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
          {/* Render OrderCard for each order */}
          {orderData?.map((order) => (
            <OrderCard
              onToastyData={onToastyData}
              key={order.order_no}
              order={order}
              statusDropdown={statusDropdown}
              orderArray={orderArray}
              handleOrderStatusFunction={handleOrderStatusFunction}
            />
          ))}
        </div>
        <div className="flex justify-center mt-1 mb-4">
          {totalPages > 0 ? (
            <Pagination
              nPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            "No Orders were found for the selected date"
          )}
        </div>
      </div>
      {spinner && (
        <div style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          background: "rgba(255,255,255,0.5)",
          display: "flex",
          zIndex: "9999",
          top: "0",
          left: "0",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {/* <ClipLoader color="#0026ff" /> */}
          <GridLoader color="#2f57ef" />
        </div>
      )}
      <Footer />
    </>
  );
};

export default Orders;
