import React from 'react'
import alert from '../assets/elert.svg'
import question from '../assets/question.svg'

function ConfirmationPopup(props) {
    const { type, confirm, reject, text } = props;
    // const type  = "alewrt";
    return (
        <div className='text-center'>
            <div className='w-full pt-3 pb-6 flex justify-center'>
                <img className={`${type === "alert" ? "rotate-180 h-[70px] w-[70px]" : "h-[60px] w-[60px] my-1"} `} src={type === "alert" ? alert : question} alt="" />
            </div>
            <div className='flex justify-center text-xl font-bold mb-7'>{text}</div>
            {type === "alert" ? (
                <button onClick={confirm} className='h-[40px] w-[250px] bg-blue-500 text-gray-50 hover:text-white font-bold text-lg hover:bg-blue-600' style={{borderRadius: "30px"}}>Continue</button>          
            ):(
                <div className='flex justify-center'>
                <button onClick={confirm} className='h-[40px] w-[110px] bg-blue-500 text-gray-50 hover:text-white font-bold text-md hover:bg-blue-600 mr-2' style={{borderRadius: "30px"}}>Yes</button>          
                <button onClick={reject} className='h-[40px] w-[110px] bg-gray-500 text-gray-50 hover:text-white font-bold text-md hover:bg-gray-600 ml-2' style={{borderRadius: "30px"}}>No</button>          
                </div>
            )}
        </div>
    )
}

export default ConfirmationPopup