// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    display: flex;
    height: 100vh; /* Set the height to the full viewport height */
  }
  
  /* Add this style to Sidebar.css if you have separate styles for Sidebar */
  .Sidebar {
    width: 240px; /* Set the width of the Sidebar */
    background-color: #333; /* Set the background color of the Sidebar */
    color: white;
    padding: 20px;
  }
  
  /* Add this style to Pages.css if you have separate styles for Pages */
  .Pages {
    flex: 1 1; /* Let Pages take the remaining space */
    padding: 20px;
    overflow-y: auto; /* Allow Pages to scroll if needed */
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,aAAa,EAAE,+CAA+C;EAChE;;EAEA,0EAA0E;EAC1E;IACE,YAAY,EAAE,iCAAiC;IAC/C,sBAAsB,EAAE,4CAA4C;IACpE,YAAY;IACZ,aAAa;EACf;;EAEA,sEAAsE;EACtE;IACE,SAAO,EAAE,uCAAuC;IAChD,aAAa;IACb,gBAAgB,EAAE,oCAAoC;EACxD","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap\");\r\n\r\n.App {\r\n    display: flex;\r\n    height: 100vh; /* Set the height to the full viewport height */\r\n  }\r\n  \r\n  /* Add this style to Sidebar.css if you have separate styles for Sidebar */\r\n  .Sidebar {\r\n    width: 240px; /* Set the width of the Sidebar */\r\n    background-color: #333; /* Set the background color of the Sidebar */\r\n    color: white;\r\n    padding: 20px;\r\n  }\r\n  \r\n  /* Add this style to Pages.css if you have separate styles for Pages */\r\n  .Pages {\r\n    flex: 1; /* Let Pages take the remaining space */\r\n    padding: 20px;\r\n    overflow-y: auto; /* Allow Pages to scroll if needed */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
