import React from "react";
// import { UpdateStatus } from "../services/Inventory";

const LedgerCard = ({ item, index, setSelection, isPopup = false }) => {
  const {can_seller, del, can, pro, outlet_name, total, unique_key_id} = item;

  // const navigation = useNavigate();

  const handleDetailsClick = async (id) => {
    // console.log('selection ', selection);
    await setSelection(id);

  };

  const renderLabelValue = (label, value, id = '') => (
    <div className={"mb-2 "+ (label === 'Seller' ? "col-span-2": "")}>
      <span className={"text-slate-400 text-sm"}>{label}</span><br />
      <span className={(label === 'Seller' && !isPopup) ? "font-bold cursor-pointer": ""} onClick={() => {!isPopup && handleDetailsClick(id)}}>{value}</span>
    </div>
  );

  return (
    <div className="bg-white p-3 mb-5 ml-2 rounded-lg shadow-md">

        {renderLabelValue("Seller", outlet_name, unique_key_id)}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        
        <div className="grid grid-cols-3">
          {renderLabelValue("Total", total)}
          {renderLabelValue("Delivered", del)}
          {renderLabelValue("Delivered (%)", ((total > 0) ? (parseInt(del)/parseInt(total))*100 : 0).toFixed(2) + ' %')}
        </div>
        <div className="grid grid-cols-3 md:ml-10">
          {renderLabelValue("Cancelled", can)}
          {renderLabelValue("In Process", pro)}
          {renderLabelValue("Fill Rate (%)", ((total > 0) ? ((parseInt(del)+parseInt(can)+parseInt(pro)) / parseInt(total))*100 : 0).toFixed(2) + ' %')}
        </div>
        <div className="grid grid-cols-3 md:ml-10">
          {renderLabelValue("Seller Cancel", can_seller)}
          {renderLabelValue("Seller Cancel (%)", ((can > 0) ? (parseInt(can_seller) / parseInt(can))*100 : 0).toFixed(2) + ' %')}
          {renderLabelValue("", '')}

        </div>

      </div>
      {/* {renderLabelValue("Buyer Cancel", can_buyer)} */}
    </div>
  );
};

export default LedgerCard;
