import { axiosCall } from "./common";
import { ORDER_URL } from "../helper";

export const ledgerData = async (formDate, toDate, providerId = '', buyerApp = '') => {
  try {
    // Assuming you have a header named 'Authorization' with a token value
    const headers = {
      Authorization: localStorage.getItem('token'), // Replace with your actual token
      // Other headers if needed
    };
    let data = {from_date: formDate, to_date: toDate};
    if(providerId != '') {
      data.provider_id = providerId;
    }
    if(buyerApp != '') {
      data.buyer_app = buyerApp;
    }
    var response = await axiosCall(ORDER_URL + '/ledger', 'post', data, headers);
    return response;
  } catch (error) {
    if(error.response.status === 401) {
      // localStorage.removeItem("userType");
      // localStorage.removeItem("outlet_name");
      // localStorage.removeItem("token");
      return false;
    }
    throw error;
  }
};

export const getMatricsFilter = async (filterData) => {
  try {
    // Assuming you have a header named 'Authorization' with a token value
    let data = {};
    data.buyer_app = filterData?.buyerApp;
    data.from_date = filterData?.initialDate;
    data.to_date = filterData?.finalDate;
    if (filterData.providerId != "") data.provider_id = filterData?.providerId;
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    var response = await axiosCall(ORDER_URL + '/ledger/metrics', 'post', data, headers);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBuyerList = async (id) => {
  try {
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    var response = await axiosCall(ORDER_URL + '/buyer/list', 'get', {}, headers);
    return response;
  } catch (error) {
    throw error;
  }
};