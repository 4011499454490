import React, { useEffect, useState } from "react";
import { ledgerData } from "../../services/Ledger";
import Card from "../../components/Card";
import LedgerCard from "../../components/LedgerCard";
import LedgerBuyerNPCard from "../../components/LedgerBuyserNPCard";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Ledger = ({onToastyData}) => {
  const navigation = useNavigate();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const [ledger, setLedger] = useState([]);
  const [selection, setSelection] = useState("");
  const [popupBuyerData, setPopupBuyerData] = useState([]);
  const [isBuyerAppOpen, setIsBuyerAppOpen] = useState(false);
  const [selectionBuyer, setSelectionBuyer] = useState("");
  const [popupRetailerData, setPopupRetailerData] = useState([]);
  const [isRetailerOpen, setIsRetailerOpen] = useState(false);
  
  const [ledgerTotal, setLedgerTotal] = useState({
    outlet_name: "Total",
    total: 0,
    del: 0,
    can: 0,
    pro: 0,
    can_seller: 0,
    can_buyer: 0
  });
  const [buyerWiseData, setBuyerWiseData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [ledgerStats, setLedgerStats] = useState({
    total: 0,
    del: 0,
    can: 0,
    pro: 0,
    can_seller: 0
  });

  // const navigation = useNavigate();
  const userType = localStorage.getItem("userType")
  useEffect(() => {
    const fetchLedgerData = async () => {
      try {
        const result = await ledgerData(formatDate(fromDate), formatDate(toDate), selection, selectionBuyer);
        // console.log({result});
        if(result === 'logout') navigation('/login');
        if(selection !== '') {
          setPopupBuyerData(result.data.buyer_np_data);
          setIsBuyerAppOpen(true);
          setSelection("");
        } else if(selectionBuyer !== '') {
          setPopupRetailerData(result.data.ledger_data);
          setIsRetailerOpen(true);
          setSelectionBuyer("");
        } else {
          setLedger(result.data.ledger_data);
          setLedgerTotal({
            outlet_name: "Total",
            total: 0,
            del: 0,
            can: 0,
            pro: 0,
            can_seller: 0,
            can_buyer: 0
          });
          setBuyerWiseData(result.data.buyer_np_data);
          let stats = {total: 0, del: 0, can: 0, pro: 0, can_seller: 0};
          result.data.ledger_data.reduce((a,v) =>  {
            stats.total += v.total;
            stats.del += parseInt(v.del);
            stats.can += parseInt(v.can);
            stats.pro += parseInt(v.pro);
            stats.can_seller += parseInt(v.can_seller);
            setLedgerStats(stats);
            return true;
          } , 0 );
          let labels = [];
          let total = [];
          let del = [];
          let can = [];
          result.data.graph_data.reverse().map((dt) => {
            // console.log('dataaaa ', dt.min_date);
            labels.push(new Date(dt.min_date).getDate() +'-'+ new Date(dt.min_date).toLocaleString('default', { month: 'long' }));
            total.push(parseInt(dt.total));
            del.push(parseInt(dt.del));
            can.push(parseInt(dt.can));
            return true;
          });
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    fetchLedgerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, selection, selectionBuyer]);

  const formatDate = (date) => {
    return date !== '' ? new Date(date).toISOString().split("T")[0]: '';
  };

  return (
    <>
      <Header onToastyData={onToastyData} />
      <div className="mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
          <Card
            title="Total Orders"
            quantity=""
            amount={ledgerStats.total}
            type="total"
          />
          <Card
            title="Delivered Orders"
            quantity=""
            amount={ledgerStats.del}
            type="del"
          />
          <Card
            title="Cancelled Orders"
            quantity=""
            amount={ledgerStats.can}
            type="can"
          />
          <Card
            title="Pending Deliveries"
            quantity=""
            amount={ledgerStats.pro}
            type="pen"
          />
          <Card
            title="Fill Rate"
            quantity=""
            amount={
              ledgerStats.total > 0
                ? (
                    ((ledgerStats.del + ledgerStats.can + ledgerStats.pro) /
                      ledgerStats.total) *
                    100
                  ).toFixed(2) + " %"
                : 0 + " %"
            }
            type="fill_per"
          />
          <Card
            title="Seller Cancellation"
            quantity=""
            amount={
              ledgerStats.can > 0
                ? ((ledgerStats.can_seller / ledgerStats.can) * 100).toFixed(
                    2
                  ) + " %"
                : 0 + " %"
            }
            type="can_seller_per"
          />
        </div>

        <div className="flex mt-10 gap-5">

          <div className="h-full p-1 bg-green-300 rounded border border-gray-500 cursor-pointer" title="Graps" onClick={()=>navigation("/matrix-card")}><i className="bi bi-graph-up text-3xl mt-1 "></i></div>
          <div class="did-floating-label-content w-100">
            <input
              class="did-floating-input"
              type="date"
              placeholder="dd-mm-yyyy"
              value={fromDate}
              max={toDate}
              onChange={(e) => setFromDate(e.currentTarget.value)}
              maxDate={new Date()}
            />
            <label class="did-floating-label">From Date</label>
          </div>

          <div class="did-floating-label-content w-50">
            <input
              class="did-floating-input"
              type="date"
              // placeholder="dd-mm-yyyy"
              value={toDate}
              min={fromDate}
              onChange={(e) => setToDate(e.currentTarget.value)}
              maxDate={new Date()}
            />
            <label class="did-floating-label">To Date</label>
          </div>  
        </div>

        {userType === "admin" && (
          <>
          <section className="p-2 rounded-lg pl-6" style={{ backgroundColor: "#d2eef1" }}>
            <legend>
              <span class="mb-2 text-xl font-bold text-grey-300 md:text-xl lg:text-xl bg-clip-text">
                Retailer-wise Data
              </span>
            </legend>
          </section>
          <div className="flex mt-8 grid grid-cols-1">
            {ledger.map((item, index) => {
                  const tempTotal = ledgerTotal;
                  tempTotal.total += parseInt(item.total);
                  tempTotal.del += parseInt(item.del);
                  tempTotal.can += parseInt(item.can);
                  tempTotal.pro += parseInt(item.pro);
                  tempTotal.can_seller += parseInt(item.can_seller);
                  tempTotal.can_buyer += parseInt(item.can_buyer);
                  return (
                    <LedgerCard
                      key={item.unique_key_id}
                      index={index}
                      item={item}
                      setSelection={setSelection}
                    />
                  );
                })
              }
            {ledger.length > 1
              ? // <LedgerCard key="total" index={ledger.length} item={ledgerTotal} />
                ""
              : ""}
          </div>
          </>
          )}

        <section className="p-2 rounded-lg pl-6" style={{ backgroundColor: "#d2eef1" }}>
            <legend>
              <span class="mb-2 text-xl font-bold text-grey-300 md:text-xl lg:text-xl bg-clip-text">
                BuyerApp-wise Data
              </span>
            </legend>
          </section>
        <div className="flex mt-8 grid grid-cols-1">
          {buyerWiseData.map((item, index) => (
            <LedgerBuyerNPCard key={item.bap_id} index={index} item={item} setSelectionBuyer={setSelectionBuyer} />
          ))}
        </div>
        <Modal
          className="modal-design h-[70vh]"
          isOpen={isBuyerAppOpen}
          // onRequestClose={closeModal}
          contentLabel="XYZ Component Modal"
        >
          <div className="relative flex h-full flex-col rounded-lg bg-white shadow">
            <div className="flex items-center justify-between rounded-t border-b border-gray-600 pt-3 pb-2 pl-7 pr-4">
              <h3 className="text-xl font-medium text-gray-900">BuyerApp-wise data</h3>
              <button
              onClick={()=>setIsBuyerAppOpen(false)}
                aria-label="Close"
                className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                type="button"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="h-5 w-5"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex-1 overflow-auto p-2">
              {/* <div className="space-y-2 py-5 px-3"> */}
                <div className="flex mt-2 grid grid-cols-1">
                  {popupBuyerData.map((item, index) => (
                    <LedgerBuyerNPCard key={item.bap_id} index={index} item={item} setSelectionBuyer={null} isPopup={true} />
                  ))}
                </div>
              {/* </div> */}
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-design h-[70vh]"
          isOpen={isRetailerOpen}
          // onRequestClose={closeModal}
          contentLabel="XYZ Component Modal"
        >
          <div className="relative flex h-full flex-col rounded-lg bg-white shadow">
            <div className="flex items-center justify-between rounded-t border-b border-gray-600 pt-3 pb-2 pl-7 pr-4">
              <h3 className="text-xl font-medium text-gray-900">Retailer-wise data</h3>
              <button
              onClick={()=> setIsRetailerOpen(false)}
                aria-label="Close"
                className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                type="button"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="h-5 w-5"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex-1 overflow-auto p-2">
              {/* <div className="space-y-2 py-5 px-3"> */}
                <div className="flex mt-2 grid grid-cols-1">
                  {popupRetailerData.map((item, index) => (
                    <LedgerCard key={item.unique_key_id}
                      index={index}
                      item={item}
                      setSelection={null}
                      isPopup={true} 
                    />
                  ))}
                </div>
              {/* </div> */}
            </div>
          </div>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default Ledger;
