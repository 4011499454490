import React from "react";
import SplashScreen from "../../components/SplashScreen";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { directLogin } from "../../services/auth";

const Splash = () => {
  const param = useParams()
  const navigation = useNavigate();

  const requestToCheckDirectLogin = async (uid, phone, id = undefined, order = undefined) => {
    try {
      // Call the login function with user data
      const result = await directLogin({ uid: uid, mobile_no : phone });
      if (result?.success) {
        
        localStorage.setItem("userType", result.userType);
        localStorage.setItem(
          "outlet_name",
          result.userType === "seller"
            ? result.user_details[0].outlet_name
            : "Admin"
        );
        localStorage.setItem("token", result.token);
        if (!id) {
          if(result.is_bank === true){
            localStorage.setItem("BankMobile", result.user_details[0]?.mobile_no);
            navigation("/bank-details");
          }else{
            navigation("/orders");
          }
        }else{
          if(order === 'order') {
            navigation("/orders-details", { state: id });
          } else if(order === 'inventory') {
            navigation("/inventory", { state: id });
          } else {
            navigation("/error", { state: id });
          }
        }
        setTimeout(() => {
        }, 1500);
      } else {
        setTimeout(() => {
          localStorage.setItem("uid", uid);
          localStorage.setItem("mobile", phone);
          navigation("/register");
        }, 1000);
      }
      if (window?.flutter_inappwebview){
        localStorage.setItem("login_type", "app");
      }else{
        localStorage.setItem("login_type", "direct");
      };
    } catch (error) {
      console.error("Error during login:", error);
      setTimeout(() => {
        navigation("/register");
      }, 1000);
    }
  };

  useEffect(() => {
    localStorage.removeItem("login_type");
    localStorage.removeItem("userType");
    localStorage.removeItem("outlet_name");
    localStorage.removeItem("token");
    localStorage.removeItem("camera-permission-granted");
    localStorage.removeItem("firstRender");
    localStorage.removeItem("orderPageDate");
    const paramLength = Object.keys(param).length;
    if (paramLength === 4){
      setTimeout(() => {
        requestToCheckDirectLogin(param.uid, param.phone, param.id, param.type);
      }, 1500);
    }else{
      setTimeout(() => {
        requestToCheckDirectLogin(param.uid, param.phone);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (token && token != "" && link != "" && id != "") {
  //     navigation("/orders-details", { state: id });
  //   }
  // }, [token , id]);

  return (
    <div>
      <SplashScreen />
    </div>
  );
};

export default Splash;
