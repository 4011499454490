import React, { useEffect, useState } from "react";
import { getSettings, UpdateStatus } from "../../services/Settings";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Toasty from "../../components/Toastify";

const Settings = ({ onToastyData, setBankDetails }) => {
  const navigation = useNavigate();
  const [storeStatus, setStoreStatus] = useState(false);

  const ToastyTime = 2000;
  const [showToasty, setShowToasty] = useState(false);
  const [toastyMessage, setToastyMessage] = useState("");
  const [toastyStatus, setToastyStatus] = useState("");
  const [toastyType, setToastyType] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [self_delivery, setSelf_delivery] = useState("");
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        if (userType === "seller") {
          const result = await getSettings();
          const {data} = result;
          if (data === "logout") navigation("/login");
          setStoreStatus(data.active === "Yes" ? true : false);
          setFromTime(data?.timings_from.replace(/(\d{2})(\d{2})/, '$1:$2'))
          setToTime(data?.timings_to.replace(/(\d{2})(\d{2})/, '$1:$2'))
          setBankDetails(result?.bank_details[0])
          setSelf_delivery(data?.self_delivery)
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };
    fetchSettingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  const openEditbankPage=()=>{
    navigation("/edit-bank-details")
  }

  const handleStatusChange = async (val) => {
    const { checked } = val?.target;
    const status = checked ? "Yes" : "No";
    const resp = await UpdateStatus(status);
    if (resp !== false) setStoreStatus(checked);
    if (resp.status === "success") {
      setToastyType("success");
    } else {
      setToastyType("warning");
    }
    setToastyMessage(resp?.message);
    setToastyStatus(resp?.status);
    setShowToasty(true);
    setTimeout(() => {
      setShowToasty(false);
    }, ToastyTime + 500);
  };


  return (
    <>
      <Header onToastyData={onToastyData} />
      <div className="mb-12 grid grid-cols-1 mt-3">
        <div className="bg-white p-2 sm:p-6 rounded shadow-lg m-6 xs:m-2 grid xs:grid-cols-1 sm:grid-cols-2">
          <label
            htmlFor="storeStatus"
            className="block text-gray-700 text-sm font-bold m-2 xs:m-2 flex justify-center sm:justify-start"
          >
            Store Status
          </label>
          <div className="flex justify-center sm:justify-end m-2">
            <span className="mr-3 items-center text-sm font-medium">
              InActive
            </span>
            <label className="inline items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                onChange={handleStatusChange}
                checked={storeStatus}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
            <span className="ml-3 items-center text-sm font-medium">
              Active
            </span>
          </div>
        </div>
        <div className="bg-white p-2 sm:p-6 rounded shadow-lg m-6 grid xs:grid-cols-1 sm:grid-cols-2">
            <label
              htmlFor="storeStatus"
              className="block text-gray-700 text-sm font-bold m-2 flex justify-center sm:justify-start"
            >
              Store Timings
            </label>
            <div className="flex justify-center sm:justify-end m-2">
            <div>
                <span
                // type="time"
                // name="fromTiming"
                className="px-1"
                style={{height: "25px"}}
                // value={fromTime}
                // onChange={handleTimeChange}
                >{fromTime}</span>
              
                <span className="mx-3">To</span>
                <span
                // type="time"
                // name="toTiming"
                className="px-1"
                style={{height: "25px"}}
                // value={toTime}
                // onChange={handleTimeChange}
                >{toTime}</span>
                
                </div>
          </div>
         
        </div>
        <div className="bg-white p-2 sm:p-6 rounded shadow-lg m-6 grid xs:grid-cols-1 sm:grid-cols-2">
            <label
              htmlFor="storeStatus"
              className="block text-gray-700 text-sm font-bold m-2 flex justify-center sm:justify-start"
            >
              Bank Details
            </label>
            <div className="flex justify-center sm:justify-end m-2">
            <div>
              <button onClick={openEditbankPage} className="bg-blue-500 text-white py-2 px-4 rounded-lg">Update Bank Details</button>
                </div>
          </div>
         
        </div>
        <div className="bg-white p-2 sm:p-6 rounded shadow-lg m-6 grid xs:grid-cols-1 sm:grid-cols-2">
            <label
              htmlFor="storeStatus"
              className="block text-gray-700 text-sm font-bold m-2 flex justify-center sm:justify-start"
            >
              Self Delivery
            </label>
            <div className="flex justify-center sm:justify-end m-2">
            <div>
            {self_delivery}
                
                </div>
          </div>
         
        </div>
      </div>
      <Footer />
      {showToasty && (
        <Toasty
          showToast={showToasty}
          message={toastyMessage}
          isSuccess={toastyStatus}
          type={toastyType}
          time={ToastyTime}
        />
      )}
    </>
  );
};

export default Settings;
