import React from "react";
import { useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toasty = ({ showToast, message, isSuccess, time }) => {
  useEffect(() => {
    if (showToast && isSuccess === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,   
        theme: "light"
       });
    } else if(showToast && isSuccess === "warning"){
      toast.error(message, {
        position: "top-right",
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true, 
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  return (
    <ToastContainer
      // hideProgressBar={false}
      newestOnTop={false}
      // closeOnClick
      rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover
      // theme="light"
      // transition={Bounce}
    />
  );
};

export default Toasty;
