import React, { useState, useEffect } from "react";
import CardHeader from "../../components/CardHeader";
import { registerBankDetails } from "../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toasty from "../../components/Toastify";
import ImageItem from "../../components/ImageItem";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  holderName: Yup.string().required("Please Enter Holder Name"),
  mobileNumber: Yup.string().required("Please Enter Mobile Number").matches(/^[6-9]\d{9}$/, 'Please Enter Valid Mobile Number'),
  accountNumber: Yup.string().required("Please Enter Account Number").matches(/^[0-9]{10,16}$/, 'Please Enter Valid Account Number'),
  ifscCode: Yup.string().required("Please Enter IFSC Code").matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Please Enter Valid IFSC Code'),
  bankName: Yup.string().required("Please Enter Bank Name"),
  branchName: Yup.string().required("Please Enter Branch Name"),
});

const initialState = {
  holderName: "",
  mobileNumber: "",
  accountNumber: "",
  ifscCode: "",
  bankName: "",
  branchName: "",
  bankAddress: "",
};

function BankDetails({ setOpen, isNew, onToastyData }) {
  const ToastyTime = 2000;
  const navigation = useNavigate();
  const [showToasty, setShowToasty] = useState(false);
  const [toastyMessaga, setToastyMessaga] = useState("");
  const [toastifyStatus, setToastifyStatus] = useState("");
  const [cheque_image, setCheque_image] = useState([]);
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
  });
  const numberPattern = /^[0-9]+$/;
  const mobileNo = localStorage.getItem("BankMobile") ?? "";
  
const handleImageUpload=(e)=>{
  const newArray = [];
  newArray.push(e.file);
  newArray.push(e.file);
  setCheque_image(newArray);
}

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "accountNumber") {
      if (numberPattern.test(value) || value === "") {
        formik.setFieldValue(`${name}`, value);
      }
    }else if(name === "mobileNumber"){
      if ((numberPattern.test(value) || value === "") && value.length<=10) {
        formik.setFieldValue(`${name}`, value);
      }
    } else {
      value = ["ifscCode"].includes(name) ? value.toUpperCase(): value;
      formik.setFieldValue(`${name}`, value);
    };
  };
  
  useEffect(() => {
    if (!window?.flutter_inappwebview) localStorage.setItem("camera-permission-granted", "true");
    formik.setFieldValue("mobileNumber", mobileNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  const handleSubmitForm = async (e) => {
    const { values } = formik;
    const formData = new FormData();
    formData.append("mobile", values?.mobileNumber);
    formData.append("ifsc", values?.ifscCode);
    formData.append("holder_name", values?.holderName);
    formData.append("account_number", values?.accountNumber);
    formData.append("bank_name", values?.bankName);
    formData.append("bank_branch", values?.branchName);
    formData.append("bank_address", values?.bankAddress);
    formData.append("bank_cheque", cheque_image[0]);
    formData.append("bank_cheque", cheque_image[1]);
    // cheque_image.forEach((image) => {
    // });
    if (formik?.isValid && formik?.dirty) {
      const result = await registerBankDetails(formData);
      console.log(result);
      if (result?.status === "success") {
        localStorage.removeItem("BankMobile")
        setToastifyStatus("success");
        onToastyData({
          success: "success",
          message: result?.message,
          isToast: true,
        });
        navigation("/orders")
      } else {
        setToastifyStatus("warning");
        setToastyMessaga("Something Went Wrong");
        setShowToasty(true);
      }
      setTimeout(() => {
        setShowToasty(false);
      }, ToastyTime + 500);
    } else {
      setToastifyStatus("warning");
      setToastyMessaga("Fill the Form Carefully");
      setShowToasty(true);
      setTimeout(() => {
        setShowToasty(false);
      }, ToastyTime + 500);
      console.log("fill the required field");
    }
  };

 

  return (
    <div className="card-page">
      <div className="card mt-5">
        <CardHeader title={"Bank Details Form"} />
        <div className="form mt-3">
          <form action="/register" method="post" onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-5">
              <div className="did-floating-label-content w-100">
                <input
                  className="did-floating-input"
                  type="text"
                  placeholder=""
                  value={formik?.values?.holderName}
                  name="holderName"
                  onChange={handleInputChange}
                />
                <label className="did-floating-label">Account Holder Name</label>
                {formik?.errors?.holderName && formik?.touched?.holderName && (
                  <div className="validation-schema-error">
                    {formik?.errors?.holderName}
                  </div>
                )}
              </div>
              <div className="did-floating-label-content w-100">
                <input
                  className="did-floating-input"
                  type="text"
                  placeholder=""
                  name="mobileNumber"
                  value={formik?.values?.mobileNumber}
                  inputMode="numeric"
                  onChange={handleInputChange}
                />
                <label className="did-floating-label">Mobile Number</label>
                {formik?.errors?.mobileNumber &&
                  formik?.touched?.mobileNumber && (
                    <div className="validation-schema-error">
                      {formik?.errors?.mobileNumber}
                    </div>
                  )}
              </div>
              <div className="did-floating-label-content w-100">
                <input
                  className="did-floating-input"
                  type="text"
                  placeholder=""
                  value={formik?.values?.accountNumber}
                  inputMode="numeric"
                  name="accountNumber"
                  onChange={handleInputChange}
                />
                <label className="did-floating-label">Account Number</label>
                {formik?.errors?.accountNumber &&
                  formik?.touched?.accountNumber && (
                    <div className="validation-schema-error">
                      {formik?.errors?.accountNumber}
                    </div>
                  )}
              </div>
              <div className="did-floating-label-content w-100">
                <input
                  className="did-floating-input"
                  type="text"
                  placeholder=""
                  name="ifscCode"
                  value={formik?.values?.ifscCode}
                  onChange={handleInputChange}
                />
                <label className="did-floating-label">IFSC Code</label>
                {formik?.errors?.ifscCode && formik?.touched?.ifscCode && (
                  <div className="validation-schema-error">
                    {formik?.errors?.ifscCode}
                  </div>
                )}
              </div>

              <div className="did-floating-label-content w-100">
                <input
                  className="did-floating-input"
                  type="text"
                  placeholder=""
                  name="bankName"
                  value={formik?.values?.bankName}
                  onChange={handleInputChange}
                />
                <label className="did-floating-label">Bank Name</label>
                {formik?.errors?.bankName && formik?.touched?.bankName && (
                  <div className="validation-schema-error">
                    {formik?.errors?.bankName}
                  </div>
                )}
              </div>

              <div className="did-floating-label-content w-100">
                <input
                  className="did-floating-input"
                  type="text"
                  placeholder=""
                  name="branchName"
                  value={formik?.values?.branchName}
                  onChange={handleInputChange}
                />
                <label className="did-floating-label">Branch Name</label>
                {formik?.errors?.branchName && formik?.touched?.branchName && (
                  <div className="validation-schema-error">
                    {formik?.errors?.branchName}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-5">
              <ImageItem
              // onClick={onImage}
                  key={2}
                  defaultText={`Upload Bank Cheque (Optional)`}
                  overlayText={`Bank Cheque`}
                  id={2}
                  onChange={handleImageUpload}
                />
            </div>
            <div className="mt-0">
              <div className="">
                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    name="bankAddress"
                    value={formik?.values?.bankAddress}
                    onChange={handleInputChange}
                  />
                  <label className="did-floating-label">Bank Address</label>
                  {formik?.errors?.bankAddress &&
                    formik?.touched?.bankAddress && (
                      <div className="validation-schema-error">
                        {formik?.errors?.bankAddress}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <button
              className="w-full mt-2"
              type="submit"
              onClick={handleSubmitForm}
            >
              Submit
            </button>
          </form>
          {showToasty && (
            <Toasty
              showToast={showToasty}
              message={toastyMessaga}
              isSuccess={toastifyStatus}
              time={ToastyTime}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BankDetails;
