import React, { useState, useEffect } from "react";
import { login } from "../../services/auth";
import { useNavigate } from "react-router-dom";

const Login = ({onToastyData}) => {
  // State variables to manage user type and login credentials
  const [userType, setUserType] = useState("seller");
  const navigation = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    providerId: "",
  });
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if(token) {
      navigation('/orders');
    }
  },);  

  // Handler function to update credentials on input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  // Handler function to submit login form
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create user data object based on user type
    const userData = {
      userType,
      email: credentials.email,
      password: credentials.password,
      uniqueiD: credentials.providerId,
    };

    try {
      // Call the login function with user data
      const result = await login(userData);
      if (result){
        localStorage.setItem("login_type", "login");
        localStorage.setItem("userType", result.userType )
        localStorage.setItem("outlet_name", (result.userType === 'seller') ? result.user_details[0]?.outlet_name: `Admin (${result?.user_details?.name})`)
        localStorage.setItem("token", result.token )
        localStorage.setItem("admin_source", result?.user_details?.source)
        navigation('/orders')
        onToastyData({isToast: true, success: "success", message: "Logged In Successfully"})
      }else{
        onToastyData({isToast: true, success: "warning", message: "Something Went Wrong"})
      }      
      // Handle the result as needed
    } catch (error) {
      console.error('Error during login:', error);
      // Handle the error
    }
  };

  // Switch user type to admin
  const switchToAdminLogin = () => {
    setUserType("admin");
  };

  // Switch user type to seller
  const switchToSellerLogin = () => {
    setUserType("seller");
  };

  return (
    <>
    {/* <Header display={"none"}/> */}
    <div className="min-h-screen flex items-center justify-center ">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9YRg252p0CgUrVrYYRnvL8MDr4jxatxWhNFuLyXr3DQ&s"
          alt="Logo"
          className="mb-4 mx-auto"
          style={{ maxWidth: '100px', maxHeight: '100px' }}
        />
        <h2 className="text-2xl font-bold mb-4">
          {userType === "seller" ? "Seller Login" : "Admin Login"}
        </h2>
        <form onSubmit={handleSubmit}>
          {/* Render email and password inputs for admin */}
          {userType === "admin" && (
            <>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={credentials.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={credentials.password}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                  required
                />
              </div>
            </>
          )}
          {/* Render provider ID input for seller */}
          {userType === "seller" && (
            <div className="mb-4">
              <label
                htmlFor="providerId"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Provider ID
              </label>
              <input
                type="text"
                id="providerId"
                name="providerId"
                value={credentials.providerId}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                required
              />
            </div>
          )}
          {/* Submit button */}
          <button
            type="submit"
            className="w-full bg-blue-700 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
          >
            Log In
          </button>
        </form>
        {/* Switch user type buttons */}
        {userType === "seller" && (
          <button
            onClick={switchToAdminLogin}
            className="mt-4 w-full bg-white text-blue-500 py-2 px-4 rounded-md hover:bg-gray-200 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
          >
            Login as Admin
          </button>
        )}
        {userType === "admin" && (
          <button
            onClick={switchToSellerLogin}
            className="mt-4 w-full bg-white text-blue-500 py-2 px-4 rounded-md hover:bg-gray-200 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
          >
            Login as Seller
          </button>
        )}
      </div>
    </div>
    </>
  );
};

export default Login;
