import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { contactUsSubmit } from "../../services/auth";
import Toasty from "../../components/Toastify";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import ondcLogo from "../../assets/ondc_logo.svg";
import Footer from "../../components/Footer";
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Please Enter Valid Email"
    ),
  message: Yup.string().required("Message is required"),
});

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  subject: "",
  message: "",
};
function Contact({ onToastyData }) {
  const navigation = useNavigate();
  const ToastyTime = 2000;
  const [showToasty, setShowToasty] = useState(false);
  const [toastyMessaga, setToastyMessaga] = useState("");
  const [toastifyStatus, setToastifyStatus] = useState("");
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(`${name}`, value);
  };

  const onSubmitContactForm = async () => {
    const { values } = formik;
    const payload = {
      name: values?.firstName + " " + values?.lastName,
      email: values?.email,
      subject: values?.subject,
      message: values?.message,
    };
    if (formik?.isValid && formik?.dirty) {
      const result = await contactUsSubmit(payload);
      if (result?.success === true) {
        setToastifyStatus("success");
        setToastyMessaga(result?.message);
        formik?.handleReset();
      } else {
        setToastifyStatus("warning");
        setToastyMessaga("Something Went Wrong");
      }
      setShowToasty(true);
      setTimeout(() => {
        setShowToasty(false);
      }, ToastyTime + 500);
    } else {
      setToastifyStatus("warning");
      setToastyMessaga("Fill the Form Carefully");
      setShowToasty(true);
      setTimeout(() => {
        setShowToasty(false);
      }, ToastyTime + 500);
    }
  };
  useEffect(() => {
    const headers = localStorage.getItem("token");
    if (!headers) {
      navigation("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header onToastyData={onToastyData} />
      <div className="min-h-screen flex items-center justify-center md:mt-10 mb-11">
        <div className="bg-white p-8 rounded shadow-md" style={{width: "500px"}}>
          <div className="items-center flex flex-col mb-2">
          <img
            className="mr-3 mt-5"
            src={ondcLogo}
            style={{ height: "100px" }}
            alt=""
          />
          </div>
          <h2 className="text-2xl font-bold mb-4">{"Contact Us"}</h2>
          <form onSubmit={formik?.handleSubmit}>
            <>
              <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={formik?.values?.firstName}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                  />
                  {formik?.errors?.firstName && formik?.touched?.firstName && (
                    <div className="validation-schema-error">
                      {formik?.errors?.firstName}
                    </div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={formik?.values?.lastName}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={formik?.values?.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                {formik?.errors?.email && formik?.touched?.email && (
                  <div className="validation-schema-error">
                    {formik?.errors?.email}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="text"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formik?.values?.subject}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="text"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Message
                </label>
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  rows="4"
                  cols="50"
                  value={formik?.values?.message}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                {formik?.errors?.message && formik?.touched?.message && (
                  <div className="validation-schema-error">
                    {formik?.errors?.message}
                  </div>
                )}
              </div>
            </>
            <button
              type="submit"
              onClick={onSubmitContactForm}
              className="w-full bg-blue-700 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer/>
          {showToasty && (
            <Toasty
              showToast={showToasty}
              message={toastyMessaga}
              isSuccess={toastifyStatus}
              time={ToastyTime}
            />
          )}
    </>
  );
}

export default Contact;
