import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    // const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    const prevOpacity = (currentPage === 1) ? '0.6': '1';
    const nextOpacity = (currentPage === nPages) ? '0.6': '1';
    const prevDecoration = (currentPage === 1) ? 'none': 'underline';
    const nextDecoration = (currentPage === nPages) ? 'none': 'underline';
    const goToNextPage = () => {
        if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
        <nav>
            <ul className='pagination justify-content-center hover:cursor-pointer' style={{display: 'inline'}}>
                <li key={0} className="page-item" onClick={goToPrevPage} style={{display: 'inline', padding: '6px', textDecoration: prevDecoration, opacity: prevOpacity}}>
                        Previous
                </li>
                {
                    (currentPage === 1 || currentPage === nPages) ?
                        (nPages === 1) ? 
                        <>
                            <li key={1} className="page-item" style={{display: 'inline', padding: '6px'}}>
                                1
                            </li>
                        </> :
                        (nPages === 2) ? 
                        <>
                            <li key={1} className="page-item" onClick={() => setCurrentPage(1)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                1
                            </li>
                            <li key={nPages} className="page-item" onClick={() => setCurrentPage(nPages)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                {nPages}
                            </li>
                        </> :
                        <>
                            <li key={1} className="page-item" onClick={() => setCurrentPage(1)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                1
                            </li>
                            <li key={2} className="page-item" style={{display: 'inline', padding: '6px'}}>
                                ...
                            </li>
                            <li key={nPages} className="page-item" onClick={() => setCurrentPage(nPages)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                {nPages}
                            </li>
                        </> :
                        (currentPage === 2 || currentPage === nPages - 1) ? 
                        (currentPage === 2) ?
                        <>
                            <li key={1} className="page-item" onClick={() => setCurrentPage(1)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                1
                            </li>
                            <li key={2} className="page-item" style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                2
                            </li>
                            <li key={nPages + 1} className="page-item" style={{display: 'inline', padding: '6px'}}>
                                ...
                            </li>
                            <li key={nPages} className="page-item" onClick={() => setCurrentPage(nPages)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                {nPages}
                            </li>
                        </> : 
                        <>
                            <>
                                <li key={1} className="page-item" onClick={() => setCurrentPage(1)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                    1
                                </li>
                                <li key={2} className="page-item" style={{display: 'inline', padding: '6px'}}>
                                    ...
                                </li>
                                <li key={nPages - 1} className="page-item" style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                    {nPages - 1}
                                </li>
                                <li key={nPages} className="page-item" onClick={() => setCurrentPage(nPages)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                    {nPages}
                                </li>
                            </>
                        </> :
                        <>
                            <li key={1} className="page-item" onClick={() => setCurrentPage(1)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                1
                            </li>
                            <li key={2} className="page-item" style={{display: 'inline', padding: '6px'}}>
                                ...
                            </li>
                            <li key={currentPage} className="page-item" style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                {currentPage}
                            </li>
                            <li key={nPages-1} className="page-item" style={{display: 'inline', padding: '6px'}}>
                                ...
                            </li>
                            <li key={nPages} className="page-item" onClick={() => setCurrentPage(nPages)} style={{display: 'inline', padding: '6px', textDecoration: 'underline'}}>
                                {nPages}
                            </li>
                        </>
                }
                <li key={nPages} className="page-item" onClick={goToNextPage} style={{display: 'inline', padding: '6px', textDecoration: nextDecoration, opacity: nextOpacity}}>
                    {/* <a className="page-link" onClick={goToNextPage} href='#'> */}
                        Next
                    {/* </a> */}
                </li>
            </ul>
        </nav>
    )
}

export default Pagination