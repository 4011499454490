import { useEffect, useState } from "react";
import { OrderStautsAndDetail } from "../services/Orders";
import Modal from "react-modal";
import { GridLoader } from "react-spinners";
import upload_csv from "../assets/upload_csv.png";
import Products from "./Products";
import GenericModal from "./GenericModal";
Modal.setAppElement("#root");

const cancelReason = [
  ["002", "One or more items in the Order not available"],
  ["005", "Merchant rejected the order"],
  ["011", "Buyer not found or cannot be contacted"],
  ["012", "Buyer does not want product any more"],
  ["013", "Buyer refused to accept delivery"],
  ["014", "Address not found"],
  ["015", "Buyer not available at location"],
  ["018", "Delivery pin code not serviceable"],
  ["019", "Pickup pin code not serviceable"],
  ["020", "Order lost in transit"],
  ["021", "Packed order not complete"],
  ["998", "Order confirmation failure"],
];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    height: "40vh",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)", // D
  },
};

const ProductTable = ({ data, setOrderStatus, toastyStatus, fetchOrderDetails }) => {
  const [updatedQty, setUpdatedQty] = useState({});
  const [currentStatus, setCurrentStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modalLabel, setModalLabel] = useState("");
  const [openReason, setOpenReason] = useState(false);
  const [reasonCode, setReasonCode] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [productData, setProductData] = useState({})
  const [invoiceImage, setInvoiceImage] = useState([])

  useEffect(() => {
    if (data && data.length > 0) {
      // Set the initial status once data is available
      setCurrentStatus(data[0].order_status);
    }
    setProductData(data?.map(item => ({
      "id": item.id,
      "order_qty": item.qty
    })))
  }, [data]);

  const handleOpenModal = (evt) => {
    if (evt?.target?.name === "cancel") {
      setModalLabel("Cancel");
      setIsOpen(true);
    } else {
      if (currentStatus === "Accepted") {
        let isOrderQty = false;
        for (let key of productData) {
          // eslint-disable-next-line eqeqeq
          if (key.order_qty != 0) {
            isOrderQty = true;
            break;
          }
        }
        if (isOrderQty && invoiceImage.length>0) {
          setModalLabel(
            getNextStatus(currentStatus).charAt(0).toUpperCase() +
            getNextStatus(currentStatus).slice(1).toLowerCase()
          );
          setIsOpen(true);
        } else {
          toastyStatus({ status: "warning", message: isOrderQty ? "Please Upload Invoice Docs/Image":"There should atleast one item Greater than zero" });
        }
      } else {
        setModalLabel(
          getNextStatus(currentStatus).charAt(0).toUpperCase() +
          getNextStatus(currentStatus).slice(1).toLowerCase()
        );
        setIsOpen(true);
      }
    }
  };

  useEffect(() => {
    console.log(productData);
  }, [productData])

  const getNextStatus = (currentStatus) => {
    // Define the order of statuses in the cycle
    const statusCycle = [
      "Created",
      "Accepted",
      "Packed",
      "Picked",
      "DISPATCHED",
      "Delivered",
    ];

    // Find the index of the current status in the cycle
    const currentIndex = statusCycle.indexOf(currentStatus);

    // If the current status is not in the cycle, return the current status
    if (currentIndex === -1) {
      return currentStatus;
    }

    // Get the next status in the cycle
    const nextIndex = (currentIndex + 1) % statusCycle.length;
    // const newStatus =
    //   statusCycle[nextIndex].charAt(0).toUpperCase() +
    //   statusCycle[nextIndex].slice(1).toLowerCase();
    return statusCycle[nextIndex];
  };


  const imageUploadChange = (evt) => {
    const updatedObject = [...invoiceImage];
    updatedObject.push(evt.target.files[0]);
    updatedObject.push(evt.target.files[0]);
    setInvoiceImage(updatedObject);
  };


  async function handleMarkAs() {
    setSpinner(true);
    // Get the next status in the cycle
    const nextStatus = getNextStatus(currentStatus);

    let payloadObject;
    if (currentStatus === "Accepted") {
      payloadObject = new FormData();
      payloadObject.append("order_no",data[0]?.order_no)
      payloadObject.append("status",nextStatus)
      productData.forEach(element => {
        console.log(element, "anurag");
        payloadObject.append("items", JSON.stringify(element))
      })
      payloadObject.append("invoice_docs",invoiceImage[0])
      payloadObject.append("invoice_docs",invoiceImage[1])
    } else {
      payloadObject = {
        order_no: data[0]?.order_no,
        status: nextStatus
      }
    }
    const response = await OrderStautsAndDetail(payloadObject);
    console.log(response,"anurag");
    if (response?.success && response) {
      // Update the current status state
      if (currentStatus === "Accepted") fetchOrderDetails()
      setCurrentStatus(nextStatus);
      setOrderStatus(nextStatus);

      // Reset the quantity and any other changes made
      setUpdatedQty({});
      toastyStatus({ status: "success", message: response?.message });
      setIsOpen(false);
    } else {
      toastyStatus({ status: "warning", message: response?.message || "Something went Wrong" });
      setIsOpen(false);
    }
    setSpinner(false);
  }

  const handleCancel = async () => {
    setSpinner(true);
    // Reset the quantity and any other changes made
    console.log("Cancel changes and reset data");
    const nextStatus = "Cancelled";

    // Add your logic to handle the status change for all products
    // console.log(`Mark as ${nextStatus} for all products from ${currentStatus}`);
    data.forEach((product) => {
      // Update the status for each product
      product.order_status = nextStatus;

      // Update the order_qty for each product
      // You can add your logic to calculate the new order_qty based on your requirements
      product.order_qty = updatedQty[product.id] || product.order_qty || 0;
    });
    const newArray = data.map(({ id: key1, order_qty: key2 }) => ({
      id: key1,
      order_qty: key2,
    }));
    const response = await OrderStautsAndDetail({
      order_no: data[0]?.order_no,
      status: nextStatus,
      items: newArray,
      reason_code: reasonCode,
    });
    toastyStatus(response);
    // Update the current status state
    setCurrentStatus(nextStatus);
    setOrderStatus(nextStatus);
    setUpdatedQty({});
    setSpinner(false);
    setIsOpen(false);
    setOpenReason(false);
  };


  const onClickReasonSubmit = (evt) => {
    setReasonCode(evt.target.value);
  };

  if (!data || data.length === 0) {
    return <p>No data available.</p>;
  }

  return (
    <div className="container mx-auto mt-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.map((product, index) => (
          <Products productDetails={product} status={currentStatus} setProductData={setProductData} />
          // <div
          //   key={product.id}
          //   className="bg-white rounded-md p-4 overflow-hidden shadow-md flex"
          // >
          //   {product.image && (
          //     <img
          //       src={product.image.split(",")[0]}
          //       alt={product.title}
          //       className="w-1/3 h-36 object-cover cursor-pointer mt-4"
          //     />
          //   )}
          //   <div className="w-2/3 p-4">
          //     <h3 className="font-semibold mb-2 line-clamp-2 text-sm md:text-lg">
          //       {product.title}
          //     </h3>
          //     <h5 className="text-gray-600">Qty: {product.qty}</h5>
          //     <h5 className="text-gray-600">MRP: {product.mrp}</h5>
          //     <div className="flex items-center space-x-4 mt-2">
          //       <label className="text-gray-600">Delivered:</label>
          //       {currentStatus === "Accepted" ? (
          //         <>
          //           <button
          //             onClick={() => decreaseQty(product.id)}
          //             className="text-red-500 font-bold"
          //           >
          //             -
          //           </button>
          //           <input
          //             value={updatedQty[product.id] || product.order_qty || 0}
          //             onChange={(e) =>
          //               handleQtyChange(product.id, e.target.value)
          //             }
          //             className="w-8 pl-2 border border-gray-300 p-1 rounded-md"
          //           />
          //           <button
          //             onClick={() => increaseQty(product.id)}
          //             className="text-green-500 font-bold"
          //           >
          //             +
          //           </button>
          //         </>
          //       ) : (
          //         <span className="text-gray-600">
          //           {updatedQty[product.id] || product.order_qty || 0}
          //         </span>
          //       )}
          //     </div>
          //   </div>
          // </div>
        ))}
      </div>
      <div className="mt-4">
        {!["Delivered","Cancelled"].includes(currentStatus) && (
          <>
            <button
              name="mark"
              onClick={handleOpenModal}
              className="bg-blue-500 text-white py-2 px-4 rounded-md mr-5"
            >
              Mark As{" "}
              {getNextStatus(currentStatus).charAt(0).toUpperCase() +
                getNextStatus(currentStatus).slice(1).toLowerCase()}
            </button>
            {(currentStatus === "Created" || currentStatus === "Accepted") && (
              <button
                // onClick={handleCancel}
                name="cancel"
                onClick={handleOpenModal}
                className="bg-red-500 text-white py-2 px-4 rounded-md"
              >
                Cancel
              </button>
            )}
            {currentStatus === "Accepted" && (
              <button
              className={`px-4 py-2 ${invoiceImage?.length> 0 ? "bg-green-300" : "bg-gray-300"} text-gray-800 rounded-lg ml-5`}
              onClick={() => document.getElementById("uploadFile").click()}
              >
            <div className="flex">
              <span>{invoiceImage?.length>0 ? "Uploaded" : "Upload Invoice"}</span>
              <img className={`mx-1 border-2 border-gray-300 ${invoiceImage?.length> 0 && "hidden"} rounded-lg cursor-pointer h-[23px]`}  src={upload_csv} alt="Logo" title="Download Order Data In CSV Format" />
            </div>
            <input onChange={imageUploadChange} type="file" id="uploadFile" className="hidden" accept=".pdf, .jpg, .jpeg, .png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
            </button>
            )}
          </>
        )}
      </div>
      <GenericModal
      type="confirm"
      confirmtext= {{yes: modalLabel === "Cancel" ? "Yes Cancel" : "Yes", no: "No"}}
      confirm={modalLabel === "Cancel" ? () => {
        setIsOpen(false);
        setOpenReason(true);
      } : handleMarkAs}
      reject={()=>setIsOpen(false)}
      text={`Are you sure want to mark these items as ${modalLabel}`} 
      open={isOpen}
      close={() => setIsOpen(false)}
      />
      {/* <Modal
        isOpen={isOpen}
        contentLabel="XYZ Component Modal"
        style={customStyles}
      >
        <div className="text-center mt-6">
          <p>{`Are you sure want to mark these items as ${modalLabel}`}</p>
          <div className="mt-4">
            {modalLabel === "Cancel" ? (
              <button
                className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
                onClick={() => {
                  setIsOpen(false);
                  setOpenReason(true);
                }}
              >
                Yes Cancel
              </button>
            ) : (
              <button
                className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
                onClick={handleMarkAs}
              >
                Yes
              </button>
            )}
            <button
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg"
              onClick={() => setIsOpen(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal> */}
      <Modal
        isOpen={openReason}
        contentLabel="XYZ Component Modal"
        style={customStyles}
      >
        <div className="text-center mt-4">
          <p><b>Please provide a reason to Cancel</b></p>
          <div className="mt-5 did-floating-label-content">
            <select
              name="variantUnit"
              onChange={onClickReasonSubmit}
              className="did-floating-input"
            >
              <option key={-1} value="" className="did-floating-label">
                Select One Option
              </option>
              {cancelReason?.map((item) => {
                return (
                  <option
                    key={item[0]}
                    value={item[0]}
                    className={`did-floating-label`}
                  >
                    {item[1]}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="mt-4">
            <button
              className={`mr-2 px-4 py-2 bg-blue-${reasonCode ? "500" : "200"
                } text-white rounded-lg`}
              onClick={handleCancel}
              disabled={!reasonCode}
            >
              Confirm
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg"
              onClick={() => setOpenReason(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      {spinner && (
        <div style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          background: "rgba(255,255,255,0.5)",
          display: "flex",
          zIndex: "9999",
          top: "0",
          left: "0",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <GridLoader color="#2f57ef" />
        </div>
      )}
    </div>
  );
};

export default ProductTable;
