import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import UserDashboard from "./User/UserDashboard";
import AdminDashboard from "./Admin/AdminDashboard";
import Orders from "./User/Orders";
import Ledger from "./User/Ledger";
// import Inventory from "./User/Inventory";
import OrderDetails from "./User/OrderDetails";
import Settings from "./User/settings";
import Register from "./Login/Register";
import AddProduct from "./User/AddProduct";
import Contact from "./User/Contact";
import BankDetails from "./User/BankDetails";
import EditBankDetails from "./User/EditBankDetails";
import PendingRetailers from "./Admin/PendingRetailers";
import PendingProducts from "./Admin/PendingProducts";
import MatrixCard from "../components/MatrixCard";

const Inventory = lazy(()=>import("./User/Inventory"))


const Pages = ({onShowHideClick, onToastyData}) => {

    const [userType,setUserType] = useState()
    const [bankDetails, setBankDetails] = useState()
    useEffect(()=>{
        if(localStorage.getItem('userType')){
            setUserType(localStorage.getItem('userType'))
        }
    },[userType])
    

    const Dashboard = () =>{
        if(userType === "seller"){
            return <UserDashboard/>
        }
        else if(userType === "admin"){
            return <AdminDashboard/>
        }
    }

  return (
    <div className="content linear-gradient-custom">
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/addproduct" element={<AddProduct onToastyData={onToastyData} />} />
        <Route path="/dashboard" element={<Dashboard onToastyData={onToastyData} onShowHideClick={onShowHideClick}/>} />
        <Route path="/orders" element={<Orders onToastyData={onToastyData} onShowHideClick={onShowHideClick}/>} />
        <Route path="/ledger" element={<Ledger onToastyData={onToastyData} onShowHideClick={onShowHideClick}/>} />
        <Route path="/orders-details" element={<OrderDetails onToastyData={onToastyData} onShowHideClick={onShowHideClick}/>} />
        <Route path="/inventory" element={<Suspense fallback={<div>Please Wait</div>}><Inventory onToastyData={onToastyData} onShowHideClick={onShowHideClick}/></Suspense>} />
        <Route path="/settings" element={<Settings onToastyData={onToastyData} onShowHideClick={onShowHideClick} setBankDetails={setBankDetails} />} />
        <Route path="/contact" element={<Contact onToastyData={onToastyData} onShowHideClick={onShowHideClick}/>} />
        <Route path="/bank-details" element={<BankDetails onToastyData={onToastyData}  />} />
        <Route path="/edit-bank-details" element={<EditBankDetails onToastyData={onToastyData} bankDetails={bankDetails}/>} />
        <Route path="/pendingRetailer" element={<PendingRetailers onToastyData={onToastyData}/>} />
        <Route path="/pendingProducts" element={<PendingProducts onToastyData={onToastyData}/>} />
        <Route path="/matrix-card" element={<MatrixCard onToastyData={onToastyData}/>} />
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
    </div>
  );
};

export default Pages;
