import { axiosCall } from "./common";
import { ORDER_URL } from "../helper";

export const ordersCount = async (data) => {
  try {
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    if(data?.provider_id == '') delete data.provider_id;
    const response = await axiosCall(ORDER_URL + '/order-count', 'post', data, headers );
    
    return response;
  } catch (error) {
    throw error;
  }
};
export const ordersData = async (date,page,orderStatus, providerId,action = "") => {
  try {
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    var url = ORDER_URL + '/order-data';
    var data = {date,page,orderStatus,action};
    if(providerId != '') {
      data.provider_id = providerId;
    }
    const response = await axiosCall(url, 'post', data, headers);
    return response;
  } catch (error) {
    throw error;
  }
};
export const ordersDetails = async (id) => {
  try {
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    var response = await axiosCall(ORDER_URL + '/order-data-details/'+ id, 'get', {}, headers);

    return response;
  } catch (error) {
    throw error;
  }
};
export const OrderStautsAndDetail = async (orderDetails) => {
  try {
    // Assuming you have a header named 'Authorization' with a token value
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    var response = await axiosCall(ORDER_URL + '/order-update', 'post', orderDetails, headers);
    return response;
  } catch (error) {
    throw error;
  }
};

export const OrderInvoiceData = async (userData) =>{
  try {
    var response = await axiosCall(ORDER_URL + '/order/invoice', 'get', {}, {}, {userData});
    return response;
  } catch (error) {
    throw error;
  }
}

export const UpdateBulkOrderStatus = async (data) => {
  try {
    // Assuming you have a header named 'Authorization' with a token value
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    var response = await axiosCall(ORDER_URL + '/order-update/bulk', 'post', data, headers);
    return response;
  } catch (error) {
    throw error;
  }
};

export const PushOrderStatus = async (id) => {
  try {
    const headers = {
      Authorization: localStorage.getItem('token'),
    };
    var response = await axiosCall(ORDER_URL + '/order-status/push/'+ id, 'get', {}, headers);

    return response;
  } catch (error) {
    throw error;
  }
};
