import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = (props) => {
  const { matricsDates, initialDate, finalDate, chartData } = props;
  // const [chartDetails, setChartDetails] = useState([]);
  const options = { month: 'long', day: 'numeric' };
  const formattedDate = (new Date(initialDate)).toLocaleDateString('en-US', options);
  console.log(formattedDate); // Output: "March 30"
  const lineChartOptionsOverallRevenue = {
    chart: {
      toolbar: {
        show: false
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF"
      }
    },
    colors: ["#0040ff"],
    markers: {
      size: 1,
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
  },
  tooltip: {
    enabled: true,
    enabledOnSeries: false,
    shared: false,
    followCursor: true,
    intersect: false,
    inverseOrder: false,
    custom: undefined,
    hideEmptySeries: false,
    fillSeriesColor: false,
    // theme: "light",
    style: {
      fontSize: '12px',
      // fontFamily: undefined
    },
    onDatasetHover: {
        highlightDataSeries: false,
    },
    x: {
        show: true,
        color: "#000"
    },
    y: {
        formatter: undefined,
        title: {
            formatter: (seriesName) => seriesName,
        },
    },
    z: {
        formatter: undefined,
        title: 'Size: '
    },
    marker: {
        show: false,
    },
    items: {
       display: "flex",
    },
    fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
    },
},
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      type: "line"
    },
    xaxis: {
      labels: {
        show: false,
        rotate: -25,
        rotateAlways: false,
        hideOverlappingLabels: false,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        format: undefined,
        formatter: undefined,
        datetimeUTC: true,
        datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
        },
    },
      hideOverlappingLabels: true,
      show: true,
      chart: {
        id: 'basic-line'
      },
      // type: "numeric",
      categories: matricsDates,
      title: {
        text: `${(new Date(initialDate)).toLocaleDateString('en-US', options)} to ${(new Date(finalDate)).toLocaleDateString('en-US', options)}`,
        margin: 1,
        style: {
          color: "#A3AED0",
          fontSize: "16px",
          fontWeight: "bold",
        }
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: true,
      title: {
        text: "Orders",
        style: {
          color: "#A3AED0",
          fontSize: "14px",
          fontWeight: "bold",
        }
      },
      axisBorder: {
        show: true,
        color: '#c4bcbc',
        offsetX: 0,
        offsetY: 0
    },
   
    },
    legend: {
      show: true
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5
      }
    },
    color: ["#7551FF", "#39B8FF"]
  };

  return (
    <>
      <div className="text-center text-xl font-bold">{chartData[0].name}</div>
      <ReactApexChart
        options={lineChartOptionsOverallRevenue}
        series={chartData}
        type="line"
        width="100%"
        height="100%"
      />
    </>
  );
}

export default LineChart;
