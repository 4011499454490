import React from "react";
import { buyerApps } from "../helper";
// import { UpdateStatus } from "../services/Inventory";

const LedgerBuyerNPCard = ({ item, setSelectionBuyer, isPopup = false }) => {
  const {can_seller, can_buyer, del, can, pro, bap_id, total} = item;
  // const buyerApps = {'buyer-app-preprod.ondc.org': 'ONDC PreProd', 'jiomart-buyer.ondcz5.de': 'JioMart', 'buyer.ecomviser.com': 'EcomViser', 'buyer-app-preprod-v2.ondc.org': 'ONDC PreProd V2', 'shopping-api-testing.phonepe.com': 'PhonePe'};

  const renderLabelValue = (label, value) => (
    <div className={"mb-2 "}>
      <span className="text-slate-400 text-sm	">{label}</span><br />
      <span>{value}</span>
    </div>
  );
  
  const handleDetailsClick = async (id) => {
    console.log('selection ', id);
    await setSelectionBuyer(id);

  };

  return (
    <div className="bg-white p-3 mb-5 ml-2 rounded-lg shadow-md">
        <h2 className={"text-lg font-bold mb-1 ml-2 " + (!isPopup ? "cursor-pointer": "")} onClick={() => {!isPopup && handleDetailsClick(bap_id)}}>{buyerApps.hasOwnProperty(bap_id) ? buyerApps[bap_id]: bap_id}</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">

        <div className="grid grid-cols-3">
            {renderLabelValue("Total", total)}
            {renderLabelValue("Delivered", del)}
            {renderLabelValue("Delivered (%)", ((total > 0) ? (parseInt(del)/parseInt(total))*100 : 0).toFixed(2) + ' %')} 
          </div>
		  <div className="grid grid-cols-3 md:ml-10">
            {renderLabelValue("Cancelled", can)}
            {renderLabelValue("In Process", pro)}
            {renderLabelValue("Fill Rate (%)", ((total > 0) ? ((parseInt(del)+parseInt(can)+parseInt(pro)) / parseInt(total))*100 : 0).toFixed(2) + ' %')}     
          </div>

		  <div className="grid grid-cols-3 md:ml-10">
              {renderLabelValue("Seller Cancel", can_seller)}
              {renderLabelValue("Seller Cancel (%)", ((can > 0) ? (parseInt(can_seller) / parseInt(can))*100 : 0).toFixed(2) + ' %')}
              {renderLabelValue("Buyer Cancel", can_buyer)}
          </div>
      </div>
    </div>
  );
};

export default LedgerBuyerNPCard;
