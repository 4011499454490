import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PushOrderStatus } from "../services/Orders";

const OrderCard = ({ order, statusDropdown, orderArray, handleOrderStatusFunction, onToastyData }) => {

  const [cardClick, setCardClick] = useState(false);
  const {
    order_no,
    datetime,
    status,
    total_amount,
    outlet_name,
    bap_id,
    actual_amt_paid
  } = order;
  const userType = localStorage.getItem("userType")
  useEffect(() => {
    let array = [...orderArray];
    if(statusDropdown !== "" && cardClick ){
      if (!array.includes(order_no)){
      array.push(order_no);
      handleOrderStatusFunction(array);
      }
    }else if (statusDropdown !== "" && !cardClick ){
      if (array.includes(order_no)){
        array = array.filter(item => item !== order_no)
        handleOrderStatusFunction(array);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardClick])
  

  const navigation = useNavigate();
  

  const handleViewDetailsClick = () => {
    // console.log("View details clicked for order:", order_no);
    navigation("/orders-details", { state: order_no });
  };
  const handlePushStatusClick = async () => {
   const result = await PushOrderStatus(order_no);
   if (result.status === "success") {
    onToastyData({ success: "success", message: result?.message , isToast: true })
  }else{
     onToastyData({ success: "warning", message: result?.message , isToast: true })
   }
  };

  const formatDateTime = (datetime) => {
    const formattedDate = new Date(datetime).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = new Date(datetime).toLocaleTimeString("en-IN", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const renderLabelValue = (label, value) => (
    <div className="grid grid-cols-2 mb-2">
      {label === "Status" ? (
        <span onClick={handlePushStatusClick} className="font-bold w-32">{label}:</span>
      ):(
        <span className="font-bold w-32">{label}:</span>
      )}
      <span>{value}</span>
    </div>
  );

  return (
    <div className={`mx-4 bg-${((statusDropdown !== "" && statusDropdown !== "Delivered") && cardClick) ? "blue-100": "white"} p-4 mb-5 ml-4 rounded-lg shadow-md`}>
      <div onClick={()=>{setCardClick(!cardClick)}}>
      <h2 className="text-xl font-bold mb-4">{order_no}</h2>
      {/* {renderLabelValue("Order Number", order_no)} */}
      {renderLabelValue("Buyer App", bap_id === "buyer-app-preprod.ondc.org" ? "ONDC PreProd" : bap_id)}
      {renderLabelValue("Date and Time", formatDateTime(datetime))}
      {renderLabelValue("Status", status)}
      {/* {renderLabelValue("Total Amount", `Rs ${total_amount}`)} */}
      {userType !== "seller" && renderLabelValue("Outlet Name", outlet_name)}
      
      <div className="flex justify-between items-baseline">
        <div
  
        >
          <b>₹ <span style={{fontSize: "22px"}}>{actual_amt_paid && actual_amt_paid !=="" ? actual_amt_paid: total_amount}</span></b>
        </div>
        {/* <div className="flex"> */}
        {/* <button
          className="bg-emerald-600 hover:bg-emerald-700 text-white py-2 px-4 rounded-lg mt-4 mr-2"
          onClick={handlePushStatusClick}
        >
          Push Status
        </button> */}
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mt-4 mr-2"
          onClick={handleViewDetailsClick}
        >
          Details
        </button>
        {/* </div> */}
      </div>
      </div>
    </div>
  );
};

export default OrderCard;
