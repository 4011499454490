import React, { useEffect, useState } from 'react'
import LineChart from './LineChart';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { getBuyerList, getMatricsFilter } from '../services/Ledger';
import Header from './Header';
import Footer from './Footer';
import { InventoryFilters } from '../services/Inventory';
import { buyerApps } from '../helper';
function MatrixCard({onToastyData}) {
    const [buyerApp, setBuyerApp] = useState("");
    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate] = useState("");
    const [providerId, setProviderId] = useState("");
    const [firstRender, setFirstRender] = useState(false);
    const [matricsDates, setMatricsDate] = useState([])
    const [totalOrders, setTotalOrders] = useState([])
    const [deliveredOrders, setDeliveredOrders] = useState([])
    const [cancelledOrders, setCancelledOrders] = useState([])
    const [tatOrders, setTatOrders] = useState([])
    const [outlets, setOutlets] = useState([])
    const [buyerList, setBuyerList] = useState([])
    const userType = localStorage.getItem("userType")
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );
    useEffect(() => {
        if (firstRender) {
            (async () => {
                const result = await getMatricsFilter({
                    buyerApp, initialDate, finalDate, providerId 
                })
                if (result?.status === "success") {
                    fillMatrixData(result?.data)
                }
            })();
        }
    }, [buyerApp, initialDate, finalDate, providerId, firstRender])

    useEffect(() => {
        let oneMonthAgo = new Date(new Date());
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        var today = new Date();
        // Set Final Date on First Render
        setFinalDate(today?.toISOString()?.slice(0, 10));
        today?.setMonth(today.getMonth() - 1);
        // Set Initial Date on First Render
        setInitialDate(today?.toISOString()?.slice(0, 10));
        (async function() {
            if(userType === 'admin') {
                const filters = await InventoryFilters();
                setOutlets(filters?.outlets);
              }
        })();
        (async function() {
                let filters = await getBuyerList();
                let updatedfilters = [];
                const { data } = filters;
                if (data && data.length>0){
                    data?.forEach(element => {
                        const {bap_id} = element;
                        console.log(bap_id);
                        if (buyerApps.hasOwnProperty(bap_id)){
                        updatedfilters.push({name: buyerApps[`${bap_id}`], id: bap_id})
                    }else{
                        updatedfilters.push({name: bap_id, id: bap_id})
                    }
                });
                setBuyerList(updatedfilters)
            }
            })();
            setFirstRender(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])


    function fillMatrixData(data) {
        let dates = [];
        let total = [];
        let can = [];
        let del = [];
        let tat = [];
        for (let key of data) {
            dates.push(key.theDate.slice(0, 10));
            total.push(key.total);
            del.push(Number(key.del));
            can.push(Number(key.can));
            tat.push(Number(key.tat));
        }
        setMatricsDate(dates);
        setTotalOrders(total);
        setDeliveredOrders(del);
        setCancelledOrders(can);
        setTatOrders(tat);
    }
    // console.log(matricsDates);
    // console.log(totalOrders);

    const lineChartDataOverallRevenue = [
        {
            name: "Confirmed Orders",
            data: totalOrders
        }
    ];
    const deliveredOrderData = [
        {
            name: "Delivered Orders",
            data: deliveredOrders
        }
    ];
    const cancelledOrderData = [
        {
            name: "Cancelled Orders",
            data: cancelledOrders
        }
    ];
    const tatBreach = [
        {
            name: "TAT Breach",
            data: tatOrders
        }
    ];

    return (
        <div>
            <Header onToastyData={onToastyData}/>
            <div className='text-center text-3xl mt-10'>Matrics Charts</div>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-8'>
                <div className='flex justify-between gap-4'>
                    <div class="did-floating-label-content w-1/2">
                        <input
                            class="did-floating-input"
                            type="date"
                            placeholder="dd-mm-yyyy"
                            value={initialDate}
                            //   max={toDate}
                            onChange={(e) => setInitialDate(e.currentTarget.value)}
                            maxDate={new Date()}
                        />
                        <label class="did-floating-label">From Date</label>
                    </div>

                    <div class="did-floating-label-content w-1/2">
                        <input
                            class="did-floating-input"
                            type="date"
                            //   placeholder="dd-mm-yyyy"
                            value={finalDate}
                            //   min={fromDate}
                            onChange={(e) => setFinalDate(e.currentTarget.value)}
                        // maxDate={new Date()}
                        />
                        <label class="did-floating-label">To Date</label>
                    </div>
                </div>
                <div className="did-floating-label-content" style={{ marginBottom: "0" }} >
                    <select
                        name="productCat"
                          onChange={(e) => {
                            setBuyerApp(e.target.value);
                          }}
                        className="did-floating-input"
                    // style={{ maxWidth: "400px" }}
                    >
                        <option key={-1} value="" className="did-floating-label">
                            Buyer
                        </option>
                        {buyerList?.map(
                            (category, ind) => {
                                return (
                                    <option
                                        className="did-floating-label"
                                        key={ind}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </option>
                                );
                            }
                        )}
                    </select>
                </div>
                {userType === "admin" && (
                    <div className="did-floating-label-content" style={{ marginBottom: "0" }} >
                        <select
                            name="productCat"
                              onChange={(e) => {
                                setProviderId(e.target.value);
                              }}
                            className="did-floating-input"
                        // style={{ maxWidth: "400px" }}
                        >
                            <option key={-1} value="" className="did-floating-label">
                                Select Retailer
                            </option>
                            {outlets?.map(
                                (category, ind) => {
                                    return (
                                        <option
                                            className="did-floating-label"
                                            key={ind}
                                            value={category?.unique_key_id}
                                          >
                                     {category?.outlet_name}
                                        </option>
                                    );
                                }
                            )}
                        </select>
                    </div>
                )}
            </div>
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 p-4 gap-10 mb-8">
                <div className='h-80 p-6'>
                    <LineChart
                        chartData={lineChartDataOverallRevenue}
                        matricsDates={matricsDates}
                        initialDate={initialDate}
                        finalDate={finalDate}
                    />
                </div>
                <div className='h-80 p-6'>
                    <LineChart
                        chartData={deliveredOrderData}
                        matricsDates={matricsDates}
                        initialDate={initialDate}
                        finalDate={finalDate}
                    />
                </div>
                <div className='h-80 p-6'>
                    <LineChart
                        chartData={cancelledOrderData}
                        matricsDates={matricsDates}
                        initialDate={initialDate}
                        finalDate={finalDate}
                    />
                </div>
                <div className='h-80 p-6'>
                    <LineChart
                        chartData={tatBreach}
                        matricsDates={matricsDates}
                        initialDate={initialDate}
                        finalDate={finalDate}
                    />
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default MatrixCard