import React from "react";

const Card = ({ title, quantity, amount, type, widthClass }) => {
  return (
    <div class={"flex flex-col p-4 bg-white rounded-lg shadow-lg mr-2"}>

      <div className="flex justify-between w-full">
          {type === 'total' ? 
            <div class="p-2 w-fit rounded-full bg-opacity-75" style={{backgroundColor: "#0BC5EA"}}>
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.3L19 7H7.3"/>
              </svg> 
            </div>: 
          type === 'del' ? 
            <div class="p-2 w-fit rounded-full bg-green-500 bg-opacity-75">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/>
              </svg> 
            </div> : 
          type === 'can' ? 
            <div class="p-2 w-fit rounded-full bg-red-500 bg-opacity-75">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 18 6m0 12L6 6"/>
              </svg> 
            </div> : 
          type === 'pen' ? 
            <div class="p-2 w-fit rounded-full bg-orange-500 bg-opacity-75">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V8m0 8h0m9-4a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
            </div> : 
          type === 'fill_per' ? 
            <div class="p-2 w-fit rounded-full bg-blue-500 bg-opacity-75">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.9 15.1 15 9m-5-.6h0m3.1 7.2h0M14 4a2.8 2.8 0 0 0 2.3.9 2.8 2.8 0 0 1 2.9 3 2.8 2.8 0 0 0 .9 2.1 2.8 2.8 0 0 1 0 4.2 2.8 2.8 0 0 0-.9 2.2 2.8 2.8 0 0 1-3 2.9 2.8 2.8 0 0 0-2.1.9 2.8 2.8 0 0 1-4.2 0 2.8 2.8 0 0 0-2.2-.9 2.8 2.8 0 0 1-2.9-3 2.8 2.8 0 0 0-.9-2.1 2.8 2.8 0 0 1 0-4.2 2.8 2.8 0 0 0 .9-2.2 2.8 2.8 0 0 1 3-2.9A2.8 2.8 0 0 0 9.9 4a2.8 2.8 0 0 1 4.2 0Z"/>
              </svg>
            </div> : 
            <div class="p-2 w-fit rounded-full bg-pink-500 bg-opacity-75">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.9 15.1 15 9m-5-.6h0m3.1 7.2h0M14 4a2.8 2.8 0 0 0 2.3.9 2.8 2.8 0 0 1 2.9 3 2.8 2.8 0 0 0 .9 2.1 2.8 2.8 0 0 1 0 4.2 2.8 2.8 0 0 0-.9 2.2 2.8 2.8 0 0 1-3 2.9 2.8 2.8 0 0 0-2.1.9 2.8 2.8 0 0 1-4.2 0 2.8 2.8 0 0 0-2.2-.9 2.8 2.8 0 0 1-2.9-3 2.8 2.8 0 0 0-.9-2.1 2.8 2.8 0 0 1 0-4.2 2.8 2.8 0 0 0 .9-2.2 2.8 2.8 0 0 1 3-2.9A2.8 2.8 0 0 0 9.9 4a2.8 2.8 0 0 1 4.2 0Z"/>
              </svg>
            </div>
          }
          <span class="text-xl font-bold">{quantity === '' ? '': '₹'}{amount}</span>
      </div>
      <div class="flex items-center justify-between col-span-2 p-2 mt-3">
          <span class="text-gray-500 text-sm">{title}</span>
          {quantity !== '' ? 
            <span class="text-green-500 text-sm font-semibold ml-1">{quantity}</span>
            : ''
          }
      </div>
    </div>
  //   <div className="max-w-xs mx-4 ml-0 bg-white p-2  rounded-lg shadow-md flex flex-col justify-between">
  //   <div className="flex justify-between items-center mb-2">
  //     <p className="text-xl font-bold mr-2">{title}</p>
  //     <span className="text-gray-700 mr-2"> - </span>
  //     <p className="text-gray-700 text-xl"> {quantity}</p>
  //   </div>
  //   <p className="text-gray-700 text-center">Rs. {amount}</p>
  // </div>
  

  );
};

export default Card;
