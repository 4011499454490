import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { NavLink, useNavigate } from "react-router-dom";
import GenericModal from "./GenericModal";
Modal.setAppElement("#root");

function Header(props) {
  // Function to get the current time and determine the greeting
  const navigation = useNavigate();
  const { paragraph, onToastyData, showBackButton } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showLogout, setShowLogout] = useState(true);
  const [isFlutterWebView, setIsFlutterWebView] = useState(false)


  useEffect(() => {
    window.flutter_inappwebview ? setIsFlutterWebView(true) : setIsFlutterWebView(false)
  }, [])
  

  useEffect(() => {
    const handleResize = () => {
      setShowLogout(window.innerWidth > 848);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };


  }, []);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };
  const goBack = () => {
    navigation(-1);
  };


  const clearSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login_type");
    localStorage.removeItem("userType");
    localStorage.removeItem("outlet_name");
    localStorage.setItem("toasty", "true");
    localStorage.removeItem("firstRender");
    localStorage.removeItem("uid");
    localStorage.removeItem("mobile");
    localStorage.removeItem("orderPageDate");
    localStorage.removeItem("outlet_name");
    onToastyData({
      success: "success",
      message: "Logged Out Successfully",
      isToast: true,
    });
    isFlutterWebView ? window.flutter_inappwebview.callHandler("closeWebView") : navigation("/login");
    // onToastyData({isToast: true, success: "success", message: "Logged Out Successfully"})
  };
  return (
    <>
    <div className="flex justify-between w-full ">
      <div className="flex justify-center items-center">
        {!showBackButton && (
        <button 
          className="flex items-center justify-center bg-gray-100 hover:bg-gray-400 text-white font-semibold px-3 rounded-lg mr-3"
          onClick={goBack}
          style={{
            marginTop: showLogout ? "0" : "10px",
            height: "40px"
          }}
          >
          <svg
            className="svg-icon"
            style={{
              width: "1em",
              height: "1em",
              verticalAlign: "middle",
              fill: "white",
              marginRight: "0px",
              overflow: "hidden",
            }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#000000"
              d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
            />
            <path
              fill="#000000"
              d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
            />
          </svg>
        </button>
        )}
        <div className={`mr-2 ${!showLogout && "hidden"}`}>
          <strong className={`text-${showLogout ? "xl" : "lg"}`}>
            {getGreeting()}, {localStorage.getItem("outlet_name")}
          </strong>
          {paragraph && (
            <p className={`text-${showLogout ? "md" : "sm"}`}>
              Here's what's happening with your stores today.
            </p>
          )}
        </div>
      </div>
      <div className='flex' style={{height: showLogout ? "36px" : "50px"}}>
        <NavLink
          to="/contact"
          className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-semibold px-3 rounded-lg mr-3"
          style={{
            marginTop: showLogout ? "0" : "10px",
          }}
        >
          <svg
            className="svg-icon"
            style={{
              width: "1em",
              height: "1em",
              verticalAlign: "middle",
              fill: "currentColor",
              marginRight: showLogout ? "10px" : "0px",
              overflow: "hidden",
            }}
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M896 341.333333V298.666667l-128 85.333333-128-85.333333v42.666666l128 85.333334m170.666667-298.666667H85.333333a85.333333 85.333333 0 0 0-85.333333 85.333333v597.333334a85.333333 85.333333 0 0 0 85.333333 85.333333h853.333334a85.333333 85.333333 0 0 0 85.333333-85.333333V213.333333a85.333333 85.333333 0 0 0-85.333333-85.333333M341.333333 256a128 128 0 0 1 128 128 128 128 0 0 1-128 128 128 128 0 0 1-128-128 128 128 0 0 1 128-128m256 512H85.333333v-42.666667c0-85.333333 170.666667-132.266667 256-132.266666s256 46.933333 256 132.266666m341.333334-213.333333h-341.333334V256h341.333334"
              fill=""
            />
          </svg>
          {showLogout && "Contact Us"}
        </NavLink>
        <button
          onClick={openModal}
          className="flex items-center justify-center bg-red-500 hover:bg-red-600 text-white font-semibold px-3 rounded-lg"
          style={{
            marginTop: showLogout ? "0" : "10px",
          }}
        >
          <svg
            className="svg-icon"
            style={{
              width: "1em",
              height: "1em",
              verticalAlign: "middle",
              fill: "currentColor",
              marginRight: showLogout ? "10px" : "0px",
              overflow: "hidden",
            }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M384 938.666667H213.333333c-72.533333 0-128-55.466667-128-128V213.333333c0-72.533333 55.466667-128 128-128h170.666667c25.6 0 42.666667 17.066667 42.666667 42.666667s-17.066667 42.666667-42.666667 42.666667H213.333333c-25.6 0-42.666667 17.066667-42.666666 42.666666v597.333334c0 25.6 17.066667 42.666667 42.666666 42.666666h170.666667c25.6 0 42.666667 17.066667 42.666667 42.666667s-17.066667 42.666667-42.666667 42.666667zM682.666667 768c-12.8 0-21.333333-4.266667-29.866667-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733333l183.466667-183.466667-183.466667-183.466667c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l213.333334 213.333333c17.066667 17.066667 17.066667 42.666667 0 59.733334l-213.333334 213.333333c-8.533333 8.533333-17.066667 12.8-29.866666 12.8z" />
            <path d="M896 554.666667H384c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667h512c25.6 0 42.666667 17.066667 42.666667 42.666667s-17.066667 42.666667-42.666667 42.666667z" />
          </svg>
          {showLogout && "Logout"}
        </button>
        <GenericModal
        type="confirm"
        confirm={clearSession}
        reject={()=>setIsOpen(false)}
        text={`Are you sure you want to ${isFlutterWebView ? "Exit":"log out"}?`}
        open={isOpen}
        close={closeModal}/>
        
      </div>
    </div>
    <div className={`ml-2 mt-4 ${showLogout && "hidden"}`}>
          <strong className={`text-${showLogout ? "xl" : "lg"}`}>
            {getGreeting()}, {localStorage.getItem("outlet_name")} Team!
          </strong>
          {paragraph && (
            <p className={`text-${showLogout ? "md" : "sm"}`}>
              Here's what's happening with your stores today.
            </p>
          )}
        </div>

    </>
  );
}

export default Header;
