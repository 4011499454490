import React from 'react';

// {
//     "title": "BRITANNIA nutri choice Oats Biscuits",
//     "vendor": "",
//     "product_cat": "",
//     "product_type": "Chocolates and Biscuits",
//     "sku_code": "8901063142213",
//     "variant_gram": "75",
//     "variant_unit": "gram",
//     "price": "25",
//     "compare_at_price": "",
//     "variant_barcode": "8901063142213",
//     "city": "Gurgaon",
//     "image": "https://www.bigbasket.com/media/uploads/p/l/270486_11-britannia-nutrichoice-essentials-oats-cookies-with-no-added-sugar.jpg,https://uat.pep1.in/reg_barcode/upload/89010631422136516c2b566aef16959902391328593851928130081843.jpg,https://uat.pep1.in/reg_barcode/upload/89010631422136516c2b566b6216959902667018122092704122021889.jpg,https://uat.pep1.in/reg_barcode/NULL,https://uat.pep1.in/reg_barcode/NULL,https://uat.pep1.in/reg_barcode/upload/89010631422136516c2b566b6716959903201407015844217023821930.jpg",
//     "description": "NA",
//     "available_inventory": "20",
//     "returnable": "",
//     "entry_datetime": "2024-04-09T10:41:00.000Z",
//     "outlet_name": "Modern Store",
//     "company": null,
//     "brand": null
// }


function PendingProductsCard({ data, onApproveProducts, setOpen, adminSource }) {

  const renderLabelValue = (label, value) => (
    <div className="grid grid-cols-2 mb-1 pt-1.5 px-3 admin-card-border leading-relaxed text-gray-900">
      <span className="font-bold w-3/4">{label}:</span>
      <span>{value}</span>
    </div>
  );
  const renderImage = (label, value) => (
    <div className="grid grid-cols-2 mb-1 pt-1.5 px-3 admin-card-border leading-relaxed text-gray-900">
      <span className="font-bold w-3/4">{label}:</span>
      {value === ""? (
        <span>No Image</span>
      ):(
        <img src={value} style={{objectFit: "contain"}} className="h-28 w-56 bg-gray-200" alt="" />
      )}
    </div>
  );

  return (
    <div
      className="relative flex h-full flex-col rounded-lg bg-white shadow"
    >
      <div className="flex items-center justify-between rounded-t border-b border-gray-600 pt-3 pb-2 pl-7 pr-4">
        <h3 className="text-xl font-medium text-gray-900">Product Details</h3>
        <button
        onClick={()=>setOpen(false)}
          aria-label="Close"
          className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          type="button"
        >
          <svg
            stroke="currentColor"
            fill="none"
            stroke-width="2"
            viewBox="0 0 24 24"
            aria-hidden="true"
            className="h-5 w-5"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div className="flex-1 overflow-auto p-6">
        <div className="space-y-2 py-5 px-3">
          {renderLabelValue("Vendor", data?.vendor)}
          {renderLabelValue("Mobile", data?.mobile_no)}
          {renderLabelValue("Outlet Name", data?.outlet_name)}
          {renderLabelValue("Description", data?.description)}
          {renderLabelValue("Available Inventory", data?.available_inventory)}
          {renderLabelValue("Returnable", data?.returnable)}
          {renderLabelValue("Entry Date Time", data?.entry_datetime)}
          {data?.image?.split(",")?.map((item,index)=>(
          renderImage(`Image ${index+1}`, item)

          ))}
        </div>
      </div>
      {adminSource === "super" && (
      <div className="flex items-center space-x-2 rounded-b border-gray-200 px-6 py-2 border-gray-600 border-t">
        <button
        onClick={() => onApproveProducts(data?.product_id)}
          type="button"
          className="group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none border border-transparent bg-cyan-700 text-white focus:ring-4 focus:ring-cyan-300 enabled:hover:bg-cyan-800 dark:bg-cyan-600 dark:focus:ring-cyan-800  rounded-lg">
          <span
            className="flex items-stretch transition-all duration-200 rounded-md px-4 py-1.5 text-sm"
          >Approve</span>
          </button>
          {/* <button
            type="button"
            className="group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none :ring-cyan-700 border border-gray-200 bg-white text-gray-900 focus:text-cyan-700 focus:ring-4 enabled:hover:bg-gray-100 enabled:hover:text-cyan-700 dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white rounded-lg"
          >
          <span
            className="flex items-stretch transition-all duration-200 rounded-md px-4 py-2 text-sm"
          >Close</span>
        </button> */}
      </div>
       )}
    </div>


  )
}

export default PendingProductsCard