import React, { useState, useEffect } from "react";
import CardHeader from "../../components/CardHeader";
import Form from "../../components/Register/Form";
import Modal1 from "../../components/Register/LegalDeclaration";
import Modal2 from "../../components/Register/TermsCondition";
import { useNavigate } from "react-router-dom";

const Register = ({onToastyData}) => {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const [openTandC, setOpenTandC] = useState({open: false, submit: false});
  const [isNew, setIsNew] = useState(true);
  const token = localStorage.getItem("token")
  const loginType = localStorage.getItem("login_type")

  useEffect(() => {
    if (loginType){
      if (window?.flutter_inappwebview){
        localStorage.setItem("login_type", "app");
      }else{
        localStorage.setItem("login_type", "direct");
        localStorage.setItem("camera-permission-granted", "true");
      };
    } else {
      localStorage.setItem("login_type", "login");
    }
    if (token){
      navigation('/orders')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  



  return (
    <div className="card-page">
      <div className="card">
        <CardHeader
          title={"Retail Onboarding Form on ONDC"}
          setIsNew={setIsNew}
        />
        <Form
          setOpen={setOpen}
          openTandC={openTandC}
          setOpenTandC={setOpenTandC}
          isNew={isNew}
          onToastyData={onToastyData}
        />
      </div>

      <Modal1 open={open} setOpen={setOpen} />
      <Modal2 openTandC={openTandC} setOpenTandC={setOpenTandC} />
    </div>
  );
};

export default Register;
