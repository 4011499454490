import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ordersDetails } from "../../services/Orders";
import ProductTable from "../../components/ProductTable";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Toasty from "../../components/Toastify";
import { GridLoader } from "react-spinners";


const OrderDetails = ({onToastyData}) => {
  const navigation = useNavigate()
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [orderID, setOrderID] = useState("");
  const [orderStatus, setOrderStatus] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const ToastyTime = 3000;
  const [showToasty, setShowToasty] = useState(false);
  const [toastyMessaga, setToastyMessaga] = useState("");
  const [toastifyStatus, setToastifyStatus] = useState("");
  const [spinner, setSpinner] = useState(false);
 
  const fetchOrderDetails = async () => {
    setSpinner(true)
    try {
      const result = await ordersDetails(location.state);
      if (ordersDetails === "logout") navigation("/login");
      if (result?.data.length === 0) navigation("/orders")
      setProducts(result.data);
      setOrderID(result.data[0]?.order_no);
      setOrderStatus(result.data[0]?.order_status);
      setOrderDate(result.data[0]?.datetime);
      const formattedDate = new Date(result.data[0]?.datetime).toDateString().substr(3)
      // const formattedTime = new Date(result.data[0]?.datetime).toTimeString().substr(0,5)
      console.log(formattedDate); // Output: "3/5/2024, 13:46:49"
      setSpinner(false)
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  const toastyStatus=(data)=>{
    const {status, message} = data;
    setToastifyStatus(status)
    setToastyMessaga(message)
    setShowToasty(true);
    setTimeout(() => {
      setShowToasty(false);
    }, ToastyTime+500);

  }

  useEffect(() => {
    fetchOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <>
    <Header onToastyData={onToastyData}/>
    <div className="mb-12">
      <div className={`${orderStatus === "Cancelled" ? 'bg-red-400': orderStatus === "Delivered" ? 'bg-green-200' : 'bg-yellow-300'} py-3 px-4 rounded-md flex flex-col md:flex-row justify-between mt-5`}>
        <div>
        {orderID && (
          <h2 className="text-base md:text-lg font-semibold ">Order ID: <span className="text-gray-700">{orderID}</span></h2>
        )}
        <h2 className="text-base md:text-lg font-semibold mt-1">
          Date: <span className="text-gray-700">{orderDate}</span> 
        </h2>
        </div>
        <h2 className="text-base md:text-lg font-semibold  mt-3">
          Status: <span className="text-gray-700">{orderStatus}</span> 
        </h2>
      </div>
      <ProductTable data={products} setOrderStatus={setOrderStatus} toastyStatus={toastyStatus} fetchOrderDetails={fetchOrderDetails}/>
    </div>
    {spinner && (
        <div style={{
          width: "100vw",
          height: "calc(100vh - 4rem)",
          position: "fixed",
          background: "rgba(255,255,255,0.5)",
          display: "flex",
          zIndex: "9999",
          top: "0",
          left: "0",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {/* <ClipLoader color="#0026ff" /> */}
          <GridLoader color="#2f57ef" />
        </div>
      )}
    <Footer/>
    {showToasty && (
    <Toasty showToast={showToasty} message={toastyMessaga} isSuccess={toastifyStatus} time={ToastyTime}/>
    )}
    </>
  );
};

export default OrderDetails;
