import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import alert from '../assets/elert.svg'
import question from '../assets/question.svg'
Modal.setAppElement("#root");

function GenericModal(props) {
    const [textConfirm, setTestConfirm] = useState({yes: "Yes", no: "No"});
    const { type, confirm, reject, text, open, close , confirmtext, cross } = props;
    useEffect(() => {
      if (confirmtext) setTestConfirm(confirmtext);
    }, [confirmtext])
    

    return (
        <Modal
            className="modal-design"
            isOpen={open}
            onRequestClose={close}
            contentLabel="XYZ Component Modal"
        >
            {cross !== "false" && (
            <div className="text-end" style={{marginBottom: "-1.8rem"}}>
                <button
                    onClick={close}
                    aria-label="Close"
                    className="ml-auto mr-0.5 mt-0.5 h-[2.8em] w-[2.8em] inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-blue-400 hover:text-gray-700"
                    type="button"
                >
                    <svg
                    className="ml-auto h-[2.3em] w-[2.3em]"
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        // className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </button>
            </div>
             )}
            <div className='text-center px-3'>
                <div className='w-full pt-3 pb-6 flex justify-center'>
                    <img className={`${type === "alert" ? "rotate-180 h-[70px] w-[70px]" : "h-[60px] w-[60px] my-1"} `} src={type === "alert" ? alert : question} alt="" />
                </div>
                <div className='flex justify-center modal-text-size font-bold mb-7 px-4 md:px-9'>{text}</div>
                {type === "alert" ? (
                    <button onClick={confirm} className='h-[40px] w-[250px] mb-7 bg-blue-500 text-gray-50 hover:text-white font-bold text-lg hover:bg-blue-600' style={{ borderRadius: "30px" }}>Continue</button>
                ) : (
                    <div className='flex justify-center mb-7'>
                        <button onClick={confirm} className='py-1.5 bg-blue-500 text-gray-50 hover:text-white font-bold modal-text-size hover:bg-blue-600 mr-2 px-6' style={{ borderRadius: "30px" }}>{textConfirm.yes}</button>
                        <button onClick={reject} className='py-1.5 bg-gray-500 text-gray-50 hover:text-white font-bold text-sm lg:text-md hover:bg-gray-600 ml-2 px-6' style={{ borderRadius: "30px" }}>{textConfirm.no}</button>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default GenericModal;