import React from 'react'

function Header({title}) {
  return (
    <>
    <div className="card_title">
        <h1>{title}</h1>
    </div>

    </>
  )
}

export default Header