import React, { useEffect, useState } from "react";

function Products({ productDetails, status, setProductData }) {
  const [quantity, setQuantity] = useState(productDetails?.qty);
  const decreaseQty = () => {
    // eslint-disable-next-line eqeqeq
    if (quantity != 0) {
      setQuantity(quantity - 1);
    }
  };
  const increaseQty = () => {
    // eslint-disable-next-line eqeqeq
    if (quantity != productDetails?.qty) {
      setQuantity(quantity + 1);
    }
  };

  useEffect(() => {
    setProductData((prevData) =>
      prevData?.map((item) => {
        if (item?.id === productDetails?.id) {
          return { ...item, order_qty: quantity + "" };
        }
        return item;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  return (
    <div
      key={productDetails?.id}
      className="bg-white rounded-md p-4 overflow-hidden shadow-md flex"
    >
      {productDetails?.image && (
        <img
          src={productDetails?.image.split(",")[0]}
          alt={productDetails?.title}
          className="w-1/3 h-36 object-cover cursor-pointer mt-4"
        />
      )}
      <div className="w-2/3 p-4">
        <h3 className="font-semibold mb-2 line-clamp-2 text-sm md:text-lg">
          {productDetails.title}
        </h3>
        <h5 className="text-gray-600">Qty: {productDetails?.qty}</h5>
        <h5 className="text-gray-600">MRP: {productDetails?.mrp}</h5>
        <div className="flex items-center space-x-4 mt-2">
          <label className="text-gray-600">Delivered:</label>
          {status === "Accepted" ? (
            <>
              <button onClick={decreaseQty} className="text-red-500 font-bold">
                -
              </button>
              <input
                value={quantity}
                className="w-8 pl-2 border border-gray-300 p-1 rounded-md"
              />
              <button
                onClick={increaseQty}
                className="text-green-500 font-bold"
              >
                +
              </button>
            </>
          ) : (
            <span className="text-gray-600">{productDetails?.order_qty}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Products;
