import { ORDER_URL } from "../helper";
import { axiosCall } from "./common";

export const getSettings = async () => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/settings';
      let data = {};
      const response = await axiosCall(url, 'get', data, headers);
      // console.log('response ', response);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};

export const UpdateStatus = async (status) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/settings/status/update';
      const response = await axiosCall(url, 'post', {status: status}, headers);
      // console.log('response ', response);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};

export const getPendingRetailerData = async () => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/retailers/pending_approvals';
      let data = {};
      const response = await axiosCall(url, 'get', data, headers);
      // console.log('response ', response);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};

export const enablePendingRetailer = async (id) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/retailers/enable/'+id ;
      let data = {};
      const response = await axiosCall(url, 'get', data, headers);
      // console.log('response ', response);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};

export const getPendingProductsData = async () => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/products/pending_approvals';
      let data = {};
      const response = await axiosCall(url, 'get', data, headers);
      // console.log('response ', response);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};

export const enablePendingProducts = async (id) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/products/enable/'+id ;
      let data = {};
      const response = await axiosCall(url, 'get', data, headers);
      // console.log('response ', response);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};
