import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import { enablePendingProducts, getPendingProductsData } from '../../services/Settings';
import PendingProductsCard from "../../components/PendingProductsCard";
import Modal from "react-modal";
import Header from "../../components/Header";
Modal.setAppElement("#root");

function PendingProducts({onToastyData}) {
    const navigation = useNavigate();
    const [pendingData, setPendingData] = useState([]);
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState({})
    const adminSource = localStorage.getItem("admin_source") || "";
    const closeModal = () => {
      setOpen(false);
    };
  
    async function fetchPendingData() {
      const result = await getPendingProductsData();
      setPendingData(result?.data);
    }
  
    useEffect(() => {
      if(localStorage?.getItem("userType") !== "admin"){
          navigation("/orders")
      }
      fetchPendingData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const renderLabelValue = (label, value) => (
      <div className="grid grid-cols-2 mb-2">
        <span className="font-bold w-32">{label}:</span>
        <span>{value}</span>
      </div>
    );
  
    const onApproveProducts = async (data) => {
      const result = await enablePendingProducts(data);
      // {"status":"success","message":"Retailers enabled successfully"}
      if (result.status === "success") {
        setOpen(false);
        fetchPendingData();
        onToastyData({
          success: "success",
          message: result?.message,
          isToast: true,
        });
      }
    };
  

  return (
    <>
    <Header onToastyData={onToastyData}/>
      <h1 className="md:text-3xl text-2xl text-center mt-10">Pending Products</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-7">
          {pendingData?.length > 0 && pendingData?.map((item, index) => (
            <div className="mx-0 md:mx-4 bg-white px-6 md:p-6 mb-5 md:ml-4 rounded-lg shadow-lg p-3">
              <div>
            <h2 className="text-xl font-bold mb-4">{item?.title}</h2>
            {renderLabelValue("Type", item?.product_type)}
            {renderLabelValue("Category", (item.product_cat ? item.product_cat: "--"))}
            {renderLabelValue("MRP", (item.compare_at_price ? "₹ "+item.compare_at_price: "--"))}
            {renderLabelValue("Price", "₹ "+item?.price)}
            {renderLabelValue("City", item?.city)}
            {renderLabelValue("Available Inventory", item?.available_inventory)}

                <div className="text-right">
                  <button
                    onClick={() => {
                      setModalData(item)
                      setOpen(true)
                    }}
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4 mr-2"
                  >
                    Details
                  </button>
                </div>
          </div>
        </div>
      ))}
            <Modal
              className="modal-design h-[70vh]"
              isOpen={open}
              onRequestClose={closeModal}
              contentLabel="XYZ Component Modal"
            >
              <PendingProductsCard
                setOpen={setOpen}
                data={modalData}
                onApproveProducts={onApproveProducts}
                adminSource= {adminSource}
              />
            </Modal>
    </div>
    {pendingData?.length === 0 && (
        <h1>No Pending Products Available</h1>
    )}
    </>
  )
}

export default PendingProducts