import axios from "axios";

export const axiosCall = async (url, type, data = '', headers = '', params = '') => {
    return new Promise(async (resolve) => {
        if(url === '') {
            console.log('No URL found');
            resolve(false);
        }
        if(!['get', 'post'].includes(type.toLowerCase())) {
            console.log('Method type is unidentified');
            resolve(false);
        }

        let resp;
        let respError;
        if(type.toLowerCase() === 'get') {
            await axios.get(url, {params: (params !== '' ? params: {}), headers: (headers !== '' ? headers: {})})
            .then((res) => {
                resp = res;
            })
            .catch((err) => {
                console.log('Got axios get catch error ', err);
                // resolve(false);
                respError = err;
            });
        } else if(type.toLowerCase() === 'post') {
            // const keys = Object.keys(data);
            let formData = new FormData();
            // if(url.indexOf('authheader') > 0) {
            //     await keys.forEach(async (key) => {
            //         formData.append(key, JSON.stringify(data[key]));
            //     });
            // } else {
                formData = data;
            // }
            await axios.post(url, formData, {params: (params !== '' ? params: {}), headers: (headers !== '' ? headers: {})})
            .then((res) => {
                // console.log(url, 'server responseeeeeeeeee ', res);
                resp = res;
            })
            .catch((err) => {
                console.log(url, 'Got axios post catch error ', err);
                // resolve(false);
                respError = err;
            });
        } else {
            console.log('Unidentified axios request');
            resolve(false);
        }

        if(resp && (resp?.status === 200 || resp?.status === 201)) {
            resolve(resp.data);
        } else {
            console.log('else resp ', {resp, respError});
            localStorage.setItem("ApiError", respError?.response?.data?.message);
            if(respError?.response?.status === 401) {
                localStorage.removeItem('userType');
                localStorage.removeItem('token');
                localStorage.removeItem('outlet_name');
                resolve('logout');
            }
            resolve(respError?.response?.data || false);
        }
    });
};
