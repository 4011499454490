import React, { useRef } from 'react';

const Camera = () => {
  const videoRef = useRef(null);
  const startCamera = () => {
    if (window.flutter_inappwebview){
      window.flutter_inappwebview.callHandler("scanBarcode").then((e) => {
        alert(e, "anurag")
        console.log(e)
      }
    )
    }else{

      navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        if (videoRef.current) {videoRef.current.srcObject = stream;}})
        .catch(error => {console.error('Error accessing camera:', error);});
    };
  }
  return (
    <div>
      <button className='text-center mt-5 bg-gray-400 h-[40px]' onClick={startCamera}>Start Camera</button>
      <video ref={videoRef} width="640" height="480" autoPlay playsInline />
    </div>
  );
};

export default Camera;
