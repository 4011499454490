import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  enablePendingRetailer,
  getPendingRetailerData,
} from "../../services/Settings";
import PendingRetailerCard from "../../components/PendingRetailerCard";
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
Modal.setAppElement("#root");

function PendingRetailers({ onToastyData }) {
  const navigation = useNavigate();
  const [pendingData, setPendingData] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({})

  const closeModal = () => {
    setOpen(false);
  };
  const adminSource = localStorage.getItem("admin_source") || "";
  async function fetchPendingData() {
    const result = await getPendingRetailerData();
    setPendingData(result?.data);
  }

  useEffect(() => {
    if (localStorage?.getItem("userType") !== "admin") {
      navigation("/orders")
    }
    fetchPendingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLabelValue = (label, value) => (
    <div className="grid grid-cols-2 mb-2">
      <span className="font-bold w-32">{label}:</span>
      <span>{value}</span>
    </div>
  );

  const onApproveRetailer = async (data) => {
    const result = await enablePendingRetailer(data);
    // {"status":"success","message":"Retailers enabled successfully"}
    if (result.status === "success") {
      setOpen(false);
      fetchPendingData();
      onToastyData({
        success: "success",
        message: result?.message,
        isToast: true,
      });
    }
  };

  return (
    <>
    <Header onToastyData={onToastyData}/>
      <h1 className="md:text-3xl text-2xl text-center mt-10">Pending Retailers List</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-7">
        {pendingData?.length > 0 && pendingData?.map((item, index) => (
          <div className="mx-0 md:mx-4 bg-white px-6 md:p-6 mb-5 md:ml-4 rounded-lg shadow-lg p-3">
            <div>
              <h2 className="text-xl font-bold mb-4">{item?.outlet_name}</h2>
              {/* {renderLabelValue("Order Number", order_no)} */}
              {renderLabelValue("UID", item?.uid)}
              {renderLabelValue("Mobile", item?.mobile_no)}
              {renderLabelValue("City", item?.City)}
              {renderLabelValue("GST", item?.gst)}
              {renderLabelValue("PAN", item?.fasai)}

              <div className="text-right">
                <button
                  onClick={() => {
                    setModalData(item)
                    setOpen(true)
                  }}
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4 mr-2"
                >
                  Details
                </button>
              </div>
            </div>
          </div>
        ))}
          <Modal
              className="modal-design h-[70vh]"
              isOpen={open}
              onRequestClose={closeModal}
              contentLabel="XYZ Component Modal"
            >
              <PendingRetailerCard
                setOpen={setOpen}
                data={modalData}
                onApproveRetailer={onApproveRetailer}
                adminSource={adminSource}
              />
            </Modal>
      </div>
      {pendingData?.length === 0 && (
        <h1>No Pending Retailers Available</h1>
      )}
    </>
  );
}

export default PendingRetailers;
