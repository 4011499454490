import React, { useEffect, useState } from "react";
import CardHeader from "../../components/CardHeader";
import LegalDeclaration from "../../components/Register/LegalDeclaration";
import ImageItem from "../../components/ImageItem";
import BarcodeScanner from "./BarcodeScanner";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toasty from "../../components/Toastify";
import { useNavigate } from "react-router-dom";
import {
  addProductApi,
  autoFillProductDetails,
  getCategoryList,
  getCategoryType,
} from "../../services/Inventory";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ClipLoader, GridLoader } from "react-spinners";
import GenericModal from "../../components/GenericModal";
Modal.setAppElement("#root");

const validationSchema = Yup.object().shape({
  ean: Yup.string().required("Please Enter EAN Code"),
  title: Yup.string().required("Please Enter Title"),
  vendor: Yup.string().required("Please Enter Vendor Name"),
  price: Yup.string().required("Please Enter Selling Price"),
  city: Yup.string().required("Please Enter City Name"),
  company: Yup.string().required("Please Enter Company "),
  brand: Yup.string().required("Please Enter Brand Name"),
  productCat: Yup.string().required("Please Enter Product Category"),
  productType: Yup.string().required("Please Enter Product Type"),
  // skuCode: Yup.string().required("Please Enter SKU Code"),
  variantGram: Yup.string().required("Please Enter Variant Weight"),
  variantUnit: Yup.string().required("Please Enter Variant Unit"),
  compareAtPrice: Yup.string().required("Please Enter MRP"),
  availableInventory: Yup.string().required("Please Enter Number of available Inventory"),
});


const initialState = {
  title: "",
  vendor: "",
  ean: "",
  price: "",
  city: "",
  returnable: "No",
  company: "",
  brand: "",
  productCat: "Grocery",
  productType: "",
  skuCode: "",
  variantGram: "",
  variantUnit: "",
  compareAtPrice: "",
  availableInventory: "",
  description: "",
  barCode: ""
};

function AddProduct({ onToastyData }) {
  const navigation = useNavigate();
  const [myObject, setMyObject] = useState({});
  const [clearImages, setClearImages] = useState(false);
  const [isConfirmPrice, setIsConfirmPrice] = useState(false)
  const [isComparePrice, setIsComparePrice] = useState(true);
  const [open, setOpen] = useState(false);
  const ToastyTime = 2000;
  const [showToasty, setShowToasty] = useState(false);
  const [toastyMessaga, setToastyMessaga] = useState("");
  const [toastifyStatus, setToastifyStatus] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const numberPattern = /^[0-9]+$/;
  const [isOpen, setIsOpen] = useState(false);
  const [barcodeModal, setBarcodeModal] = useState(false)
  const [isConfirmProduct, setIsConfirmProduct] = useState(false);
  const [autoFillChange, setAutoFillChange] = useState(false);
  const [autoFillImages, setAutoFillImages] = useState([]);
  const [autoFillModal, setAutoFillModal] = useState(false);
  const [loaderOption, setLoaderOption] = useState(false);
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
  });
  const openModal = () => {
    if (window?.flutter_inappwebview) {
      window.flutter_inappwebview
        .callHandler("fetchCameraPermission")
        .then((res) => {
          localStorage.setItem(
            "camera-permission-granted",
            res ? "true" : "false"
          );
          window.flutter_inappwebview.callHandler("scanBarcode").then((e) => {
            formik.setFieldValue("barCode", e);
            if (e === -1 || e === "-1") {
              console.log(e);
            } else {
              setBarcodeModal(true)
            }
          })
        })
        .catch((error) => {
          console.error("Error fetching camera permission:", error);
          // Handle error appropriately, such as displaying an error message
          alert("Error fetching camera permission. Please try again later.");
        });
    } else {
      setIsOpen(true);
    }
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const onClickVerify = (e) => {
    setIsOpen(false);
    formik.setFieldValue(`ean`, e);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80vw",
      height: "50vh",
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      ["price", "compareAtPrice", "variantGram", "availableInventory"].includes(
        name
      )
    ) {
      if (numberPattern.test(value) || value === "") {
        formik.setFieldValue(`${name}`, value);
      }
    } else if (name === "productCat") {
      formik.setFieldValue(`${name}`, value);
      (async () => {
        setLoaderOption(true);
        const data = await getCategoryType({ category: value });
        if (data?.status === "success"){
          setCategoryType(data?.data);
          setLoaderOption(false);
        }else{
          setTimeout(() => {
            setLoaderOption(false);
            setToastifyStatus("warning");
            setToastyMessaga("Something Went Wrong, Please Try Again");
            setShowToasty(true);
          }, 500);
        }
      })();
    } else {
      formik.setFieldValue(`${name}`, value);
    }
  };

  const autoFillWithEanAndSku = (data) => {
    formik.setFieldValue("title", data?.title || "");
    formik.setFieldValue("vendor", data?.vendor || "");
    formik.setFieldValue("skuCode", data?.sku_code || "");
    formik.setFieldValue("price", data?.price || "");
    formik.setFieldValue("returnable", data?.returnable === "" ? "No" : data?.returnable || "No");
    formik.setFieldValue("company", data?.company || "");
    formik.setFieldValue("brand", data?.brand || "");
    formik.setFieldValue("productCat", data?.product_cat || "");
    formik.setFieldValue("productType", data?.product_type || "");
    formik.setFieldValue("skuCode", data?.sku_code || "");
    formik.setFieldValue("variantGram", data?.variant_gram || "");
    formik.setFieldValue("variantUnit", data?.variant_unit || "");
    formik.setFieldValue("compareAtPrice", data?.compare_at_price || "");
    formik.setFieldValue("availableInventory", data?.available_inventory || "");
    formik.setFieldValue("description", data?.description || "");
  }

  useEffect(() => {
    if (!window?.flutter_inappwebview) localStorage.setItem("camera-permission-granted", "true");
    const headers = localStorage.getItem("token");
    if (!headers) {
      navigation("/login");
    }
    (async () => {
      const categoryList = await getCategoryList();
      setCategoryList(categoryList?.categories);
    })();
    (async () => {
      const data = await getCategoryType({ category: "Grocery" });
      setCategoryType(data?.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik?.values?.compareAtPrice >= Number(formik?.values?.price)) {
      setIsComparePrice(true);
    } else {
      setIsComparePrice(false);
    }
  }, [formik?.values?.price, formik?.values?.compareAtPrice]);

  useEffect(() => {
    if (formik?.values?.ean?.length > 3) {
      (async () => {
        const data = await autoFillProductDetails({ ean: formik?.values?.ean })
        if (data?.status === 'success' && data?.data?.length > 0) {
          setAutoFillImages(data?.data[0])
          setAutoFillModal(true);
        } else {
          if (data.data) autoFillWithEanAndSku(data?.data[0])
          setAutoFillChange(false)
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.ean]);

  const autoFillEanData = () => {
    setAutoFillChange(true);
    autoFillWithEanAndSku(autoFillImages);
    setAutoFillModal(false);
  }


  const imageUploadChange = (data) => {
    const updatedObject = { ...myObject, [data.id]: data.file };
    setMyObject(updatedObject);
    setImageArray(Object.values(updatedObject));
  };

  const onSaveButtonHandler = async () => {
    const { values } = formik;
    if (formik?.isValid && formik?.dirty) {
      if ((Number(values?.compareAtPrice) - Number(values?.price)) > (0.1 * values?.compareAtPrice)) {
        setIsConfirmPrice(true)
      } else {
        setIsConfirmProduct(true);
      }
    } else {
      setImageArray(Object.values(myObject));
      setToastifyStatus("warning");
      setToastyMessaga("Fill the Form Carefully");
      setShowToasty(true);
      setTimeout(() => {
        setShowToasty(false);
      }, ToastyTime + 500);
    }
  };

  const onAddProductButtonHandler = async () => {
    setSpinner(true);
    const { values } = formik;
    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("vendor", values?.vendor);
    formData.append("ean", values?.ean);
    formData.append("price", values?.price);
    formData.append("city", values?.city);
    formData.append("returnable", values?.returnable);
    imageArray.forEach((image) => {
      formData.append("prod_images", image);
    });
    formData.append("company", values?.company);
    formData.append("brand", values?.brand);
    formData.append("product_cat", values?.productCat);
    formData.append("product_type", values?.productType);
    formData.append("sku_code", values?.skuCode);
    formData.append("variant_gram", values?.variantGram);
    formData.append("variant_unit", values?.variantUnit);
    formData.append("compare_at_price", values?.compareAtPrice);
    formData.append("inventory", values?.availableInventory);
    formData.append("description", values?.description);
    const result = await addProductApi(formData);
    if (result?.status === "success") {
      setToastifyStatus("success");
      setToastyMessaga(result?.status);
      imageArray([]);
      setClearImages(true)
      formik?.handleReset();
    } else {
      setToastifyStatus("warning");
      setToastyMessaga("Something Went Wrong");
    }
    setShowToasty(true);
    setTimeout(() => {
      setShowToasty(false);
    }, ToastyTime + 500);
    setSpinner(false);
    setIsConfirmProduct(false);
  };

  return (
    <>
      <Header onToastyData={onToastyData} />
      <div className="card-page mt-6">
        <div className="card">
          <CardHeader title={"Add New Product"} />
          <div className="form">
            <form onSubmit={formik?.handleSubmit}>
              <div>
                <div className="relative mt-4 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"></div>
                  <input
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    placeholder="EAN Code"
                    name="ean"
                    value={formik?.values?.ean}
                    onChange={handleInputChange}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <button
                      type="button"
                      className="h-full"
                      onClick={openModal}
                      style={{
                        padding: "0px 10px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                      }}
                    >
                      <i className="bi bi-upc-scan"></i>
                    </button>
                    <Modal
                      isOpen={barcodeModal}
                      onRequestClose={() => setBarcodeModal(false)}
                      contentLabel="XYZ Component Modal"
                      style={customStyles}
                    >
                      <div className="text-center font-bold mt-6 text-lg">Code: {formik?.values?.barCode}</div>
                      <div className="flex justify-between">
                        <button className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
                          onClick={() => {
                            formik.setFieldValue("ean", formik.values.barCode)
                            setBarcodeModal(false)
                          }}>
                          Verify
                        </button>
                        <button onClick={() => setBarcodeModal(false)} className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-lg">Cancel</button>
                      </div>
                    </Modal>
                    <Modal
                      isOpen={isOpen}
                      onRequestClose={closeModal}
                      contentLabel="XYZ Component Modal"
                      style={customStyles}
                    >
                      <button
                        onClick={closeModal}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                      >
                        Close
                      </button>
                      <BarcodeScanner onClickVerify={onClickVerify} />
                    </Modal>
                  </div>
                </div>
                {formik?.errors?.ean && formik?.touched?.ean && (
                  <div className="validation-schema-error px-2">
                    {formik?.errors?.ean}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-5">
                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    value={formik?.values?.title}
                    name="title"
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                  />
                  <label className="did-floating-label relative">Title<span className="required-field-star">*</span></label>
                  {formik?.errors?.title && formik?.touched?.title && (
                    <div className="validation-schema-error">
                      {formik?.errors?.title}
                    </div>
                  )}
                </div>

                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    name="vendor"
                    value={formik?.values?.vendor}
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                  />
                  <label className="did-floating-label relative">Vendor<span className="required-field-star">*</span></label>
                  {formik?.errors?.vendor && formik?.touched?.vendor && (
                    <div className="validation-schema-error">
                      {formik?.errors?.vendor}
                    </div>
                  )}
                </div>
                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    value={formik?.values?.city}
                    name="city"
                    onChange={handleInputChange}
                  />
                  <label className="did-floating-label relative">City<span className="required-field-star">*</span></label>
                  {formik?.errors?.city && formik?.touched?.city && (
                    <div className="validation-schema-error">
                      {formik?.errors?.city}
                    </div>
                  )}
                </div>
                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    name="skuCode"
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                  />
                  <label className="did-floating-label relative">SKU Code<span className="required-field-star">*</span></label>
                  {formik?.errors?.skuCode && formik?.touched?.skuCode && (
                    <div className="validation-schema-error">
                      {formik?.errors?.skuCode}
                    </div>
                  )}
                </div>
                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    name="compareAtPrice"
                    inputMode="numeric"
                    value={formik?.values?.compareAtPrice}
                    onChange={handleInputChange}
                  />
                  <label className="did-floating-label relative">MRP<span className="required-field-star">*</span></label>
                  {formik?.errors?.compareAtPrice &&
                    formik?.touched?.compareAtPrice && (
                      <div className="validation-schema-error">
                        {formik?.errors?.compareAtPrice}
                      </div>
                    )}
                  {!isComparePrice && formik?.touched?.compareAtPrice && (
                    <div className="validation-schema-error">
                      "Compare Price Should be greater than Price"
                    </div>
                  )}
                </div>
                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    value={formik?.values?.price}
                    onFocus={() => {
                      if (formik?.values?.price === "" && !autoFillChange) {
                        formik.setFieldValue(
                          "price",
                          formik?.values?.compareAtPrice
                        );
                      }
                    }}
                    name="price"
                    inputMode="numeric"
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                  />
                  <label className="did-floating-label relative">Selling Price<span className="required-field-star">*</span></label>
                  {formik?.errors?.price && formik?.touched?.price && (
                    <div className="validation-schema-error">
                      {formik?.errors?.price}
                    </div>
                  )}
                </div>

                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    name="company"
                    value={formik?.values?.company}
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                  />
                  <label className="did-floating-label relative">Company<span className="required-field-star">*</span></label>
                  {formik?.errors?.company && formik?.touched?.company && (
                    <div className="validation-schema-error">
                      {formik?.errors?.company}
                    </div>
                  )}
                </div>

                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    name="brand"
                    value={formik?.values?.brand}
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                  />
                  <label className="did-floating-label relative">Brand<span className="required-field-star">*</span></label>
                  {formik?.errors?.brand && formik?.touched?.brand && (
                    <div className="validation-schema-error">
                      {formik?.errors?.brand}
                    </div>
                  )}
                </div>

                <div className="did-floating-label-content w-100">
                  <select
                    name="productCat"
                    value={formik?.values?.productCat}
                    readOnly={autoFillChange}
                    onChange={handleInputChange}
                    className="did-floating-input"
                  >
                    <option key={-1} value="" className="did-floating-label">
                      Product Category
                    </option>
                    {categoryList?.map((category, ind) => {
                      return (
                        <option
                          className="did-floating-label"
                          key={ind}
                          value={category?.product_cat}
                        >
                          {category?.product_cat}
                        </option>
                      );
                    })}
                  </select>
                  {formik?.errors?.productCat &&
                    formik?.touched?.productCat && (
                      <div className="validation-schema-error">
                        {formik?.errors?.productCat}
                      </div>
                    )}
                </div>

                <div className="did-floating-label-content w-100">
                  <select
                    name="productType"
                    readOnly={autoFillChange}
                    onChange={handleInputChange}
                    className="did-floating-input"
                  >
                    <option key={-1} value="" className="did-floating-label relative">
                      Product Type <span className="required-field-star">*</span>
                    </option>
                    {categoryType?.map((category, ind) => {
                      return (
                        <option
                          className="did-floating-label"
                          key={ind}
                          value={category?.product_type}
                        >
                          {category?.product_type}
                        </option>
                      );
                    })}
                  </select>
                  {formik?.errors?.productType &&
                    formik?.touched?.productType && (
                      <div className="validation-schema-error">
                        {formik?.errors?.productType}
                      </div>
                    )}
                </div>

                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    name="variantGram"
                    value={formik?.values?.variantGram}
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                    inputMode="numeric"
                  />
                  <label className="did-floating-label relative">Variant Weight<span className="required-field-star">*</span></label>
                  {formik?.errors?.variantGram &&
                    formik?.touched?.variantGram && (
                      <div className="validation-schema-error">
                        {formik?.errors?.variantGram}
                      </div>
                    )}
                </div>

                <div className="did-floating-label-content w-100">
                  <select
                    name="variantUnit"
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                    className="did-floating-input"
                  >
                    <option key={-1} value="" className="did-floating-label relative">
                      Variant Unit <span className="required-field-star">*</span>
                    </option>
                    {[
                      "Unit",
                      "Dozen",
                      "Gram",
                      "Kilogram",
                      "Tonne",
                      "Litre",
                      "Millilitre",
                    ].map((outlet, ind) => {
                      return (
                        <option
                          className="did-floating-label"
                          key={ind}
                          value={outlet.toLowerCase()}
                        >
                          {outlet}
                        </option>
                      );
                    })}
                  </select>
                  {formik?.errors?.variantUnit &&
                    formik?.touched?.variantUnit && (
                      <div className="validation-schema-error">
                        {formik?.errors?.variantUnit}
                      </div>
                    )}
                </div>

                <div className="did-floating-label-content w-100">
                  <input
                    className="did-floating-input"
                    type="text"
                    placeholder=""
                    name="availableInventory"
                    inputMode="numeric"
                    value={formik?.values?.availableInventory}
                    readOnly={autoFillChange}
                    onChange={!autoFillChange && handleInputChange}
                  />
                  <label className="did-floating-label relative">
                    Available Inventory<span className="required-field-star">*</span>
                  </label>
                  {formik?.errors?.availableInventory &&
                    formik?.touched?.availableInventory && (
                      <div className="validation-schema-error">
                        {formik?.errors?.availableInventory}
                      </div>
                    )}
                </div>
              </div>
              <div className="mt-5">
                <span style={{ color: "grey" }}>Returnable (Yes/No)</span>
                <div className="switch-field mt-2">
                  <input
                    type="radio"
                    id="returnable-yes"
                    name="returnable-switch"
                    defaultChecked={false}
                    onChange={(e) => {
                      formik.setFieldValue("returnable", "Yes");
                    }}
                  />
                  <label htmlFor="returnable-yes">Yes</label>
                  <input
                    type="radio"
                    id="returnable-no"
                    name="returnable-switch"
                    defaultChecked={true}
                    onChange={(e) => {
                      formik.setFieldValue("returnable", "No");
                    }}
                  />
                  <label htmlFor="returnable-no">No</label>
                </div>
              </div>
              <div className="mt-5">
                <span>Product Images</span>
                {autoFillChange ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    {autoFillImages?.image?.split(",")?.map((items, index) => (
                      <div className="certificate-img mt-3 me-0 me-md-3">
                        <label className="picture" tabIndex="0">
                          <span className="picture_image">
                            <div>
                              <small className='picture_label'>Image {index + 1}</small>
                              <img className='picture_image' src={items} alt="" />
                            </div>
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <ImageItem
                      key={1}
                      defaultText={`Image 1 *`}
                      overlayText={`Image 1 *`}
                      id={1}
                      clearImages={clearImages}
                      setClearImages={setClearImages}
                      onChange={imageUploadChange}
                    />

                    <ImageItem
                      key={2}
                      defaultText={`Image 2 *`}
                      overlayText={`Image 2`}
                      id={2}
                      onChange={imageUploadChange}
                      clearImages={clearImages}
                      setClearImages={setClearImages}
                    />
                    {imageArray.slice(1, 9).map((item, index) => {
                      return (
                        <ImageItem
                          key={index + 3}
                          defaultText={`Image ${index + 3} (optional)`}
                          overlayText={`Image ${index + 3}`}
                          id={index + 3}
                          onChange={imageUploadChange}
                          clearImages={clearImages}
                          setClearImages={setClearImages}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="mt-5">
                <span>Product Description</span>
                <div className="grid grid-cols-1 gap-3 mt-1">
                  <textarea
                    name="description"
                    id=""
                    cols="30"
                    rows="5"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
              <button
                className="w-full mt-6"
                type="submit"
                onClick={onSaveButtonHandler}
              >
                Save
              </button>
              <button
                className="w-full mt-6"
                onClick={() => {
                  setClearImages(true)
                  setImageArray([])
                }}
              >
                Save
              </button>
              <GenericModal
                type="confirm"
                confirm={onAddProductButtonHandler}
                reject={() => setIsConfirmProduct(false)}
                text="Are you sure you want to add this Product?"
                open={isConfirmProduct}
                close={() => setIsConfirmProduct(false)}
              />
              <GenericModal
                type="alert"
                confirm={() => {
                  setIsConfirmProduct(true);
                  setIsConfirmPrice(false);
                }}
                reject={() => setIsConfirmPrice(false)}
                text="You are giving more than 10% discount, are you sure want to continue?"
                open={isConfirmPrice}
                close={() => setIsConfirmPrice(false)}
              />
              
              <GenericModal
                type="confirm"
                confirm={autoFillEanData}
                reject={() => setAutoFillModal(false)}
                text="Details exist for this EAN, do you want to autofill the details?"
                open={autoFillModal}
                close={() => setAutoFillModal(false)}
              />
            </form>
            {showToasty && (
              <Toasty
                showToast={showToasty}
                message={toastyMessaga}
                isSuccess={toastifyStatus}
                time={ToastyTime}
              />
            )}
          </div>
        </div>

        <LegalDeclaration open={open} setOpen={setOpen} />
      </div>
      {spinner && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            background: "rgba(255, 255, 255,0.5 )",
            display: "flex",
            zIndex: "9999",
            top: "0",
            left: "0",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GridLoader color="#2f57ef" />
        </div>
      )}
      {loaderOption && (
        <div style={{
          width: "100vw",
          height: "calc(100vh - 4rem)",
          position: "fixed",
          background: "rgba(255,255,255,0.5)",
          display: "flex",
          zIndex: "9999",
          top: "0",
          left: "0",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <ClipLoader color="#0026ff" />

        </div>
      )}
      <Footer />
    </>
  );
}

export default AddProduct;
