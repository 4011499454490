import { ORDER_URL, base_url } from "../helper";
import { axiosCall } from "./common";

export const InventoryDetails = async (page, providerId = '', brand = '', category = '', status = '') => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/inventory/'+ page;
      let data = {};
      if(providerId !== '') {
        data.provider_id = providerId;
      } 
      if(brand !== ''){
        data.brand = brand;
      } 
      if(category !== ''){
        data.category = category;
      }
      if(status !== ''){
        data.status = status;
      }
      const response = await axiosCall(url, 'post', data, headers);
      // console.log('response ', response);
      resolve(response.data);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};

export const InventoryFilters = async () => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/inventory-filters';
      const response = await axiosCall(url, 'get', {}, headers);
      resolve(response.data);
    } catch (error) {
      console.log('catch error in service getting inventory filters ', error);
      resolve(false);
    }
  });
};

export const InventoryCategoriesByBrand = async (brand) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/inventory-categories/'+ brand;
      
      const response = await axiosCall(url, 'get', {}, headers);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory filters ', error);
      resolve(false);
    }
  });
};

export const UpdateStatus = async (itemId, status) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/inventory/status/update';
      const response = await axiosCall(url, 'post', {item_id: itemId, status}, headers);
      // console.log('response ', response);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};

export const UpdatePrice = async (updatedData) => {
  
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/inventory/details/update';
      const response = await axiosCall(url, 'post', updatedData, headers);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};


export const addProductApi = async (userdata) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/inventory/product/add';
      const response = await axiosCall(url, 'post', userdata, {'Content-Type': 'multipart/form-data', Authorization: localStorage.getItem('token')});
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};


export const getCategoryList = async () => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/products/categories/list';
      const response = await axiosCall(url, 'get', {}, headers);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory filters ', error);
      resolve(false);
    }
  });
};

export const getCategoryType = async (userdata) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/products/categories/type';
      const response = await axiosCall(url, 'post', userdata, headers);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};


export const searchInventoryProduct = async (userdata) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/inventory/product/search';
      const response = await axiosCall(url, 'post', userdata, headers);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};


export const autoFillProductDetails = async (userdata) => {
  return new Promise(async (resolve) => {
    try {
      const headers = {Authorization: localStorage.getItem('token')};
      const url = ORDER_URL + '/products/autofill';
      const response = await axiosCall(url, 'post', userdata, headers);
      resolve(response);
    } catch (error) {
      console.log('catch error in service getting inventory details ', error);
      resolve(false);
    }
  });
};